import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const GetCurrencies = (page, limit) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data, status } = await api.getCurrencies(page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_CURRENCIES, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
  }
};