import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import OTPInput from 'react-otp-input';

import { 
  Box,
  Link,
  Container, 
  TextField, 
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';
import AppTopBar from '../../components/topBar/AppTopBar';

import {
  countryList
} from "../../components/utils/countryCodeList";

import { 
  SendRegistrationCode,
  Register,
  ResendRegistrationCode
} from '../../redux/actions/authActions';
import { 
  GetProfile 
} from '../../redux/actions/profileActions';

import "./styles.scss";

const Data = {
  MobileCountryCode: "91",
  MobileNumber: ""
}

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [step, setStep] = useState(0);

  const [allowResendCode, setAllowResendCode] = useState(false);

  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    if(localStorage.getItem("code")) {
      setFormData({ ...formData, MobileCountryCode: localStorage.getItem("code") })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if(e.target.name === "MobileCountryCode"){
      localStorage.setItem("code", e.target.value)
    }
  }

  const handleSendRegisterCode = () => {
    dispatch(SendRegistrationCode(formData, setLoading)).then(({res, statusCode}) => {
      if(res.error === false){
        handleNextStep();
        setInitialTime();
        setFormData({ ...formData, ReferenceId: res.data.ReferenceId, Code: "" });
      }else{
       
      }
    })
  }

  const handleResendRegisterCode = () => {
    setAllowResendCode(false);
    setInitialTime();
    dispatch(ResendRegistrationCode(formData, setLoading));
    setFormData({ ...formData, Code: "" });
  }

  const handleRegister = () => {
    dispatch(Register(formData, navigate, setLoading)).then(({res, statusCode}) => {
      if(statusCode === 200){
        dispatch(GetProfile(setLoading));
      }
    })
  }

  // ------------------------- miscellaneous step --------------------------
  const handleNextStep = () => {
    setStep(step + 1);
  }

  const setInitialTime = () => {
    setStartTime(Date.now() + 30000);
  }

  return (
    <Box>
      <AppTopBar />
      <Container maxWidth="lg">
        <Box className="auth flexCenterCenterRow">
          <Box className="auth__content flexCenterCenterRow">
            <Box className="flex_CenterColumn" gap={"15px"} width={"380px"}>
              <Typography
                variant='h6'
                textAlign={"center"}>
                Create new account
              </Typography>
              {
                step === 0
                ?
                <>
                  <Typography
                    variant='body2'
                    textAlign={"center"}>
                    Enter your mobile number.
                  </Typography>
                  <Box
                    className="flexCenterSBRow"
                    gap={"15px"}>
                    <FormControl sx={{ minWidth: 100 }}>
                      <InputLabel id="country-code-label">Country</InputLabel>
                      <Select
                        value={formData.MobileCountryCode}
                        name="MobileCountryCode"
                        label="Country"
                        onChange={handleChange}
                        renderValue={(item) => "+" + item}
                        sx={{ width: "100px" }}
                        >
                        {
                          countryList.map((item, index) => (
                            <MenuItem key={index} value={item.dial_code}>
                              <Box className="flexCenter_Row">
                                <Typography sx={{ width: "60px" }}
                                  variant='body2'>
                                  (+{item.dial_code})
                                </Typography>
                                <Typography variant='body2'>{item.name}</Typography>
                              </Box>
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    <TextField 
                      variant="outlined"
                      label="Phone"
                      name="MobileNumber"
                      fullWidth={true}
                      placeholder='Enter your phone number'
                      value={formData.MobileNumber}
                      onChange={handleChange}
                      />
                  </Box>
                  <Typography
                    variant='caption'
                    sx={{ color: "grey" }}
                    textAlign={"center"}>
                    By clicking on the button below, you agree to receive one time verification code at this mobile number*. 
                    <br />
                    *Message and data rates may apply. 
                  </Typography>
                  <LoadingButton
                    variant='contained' 
                    loading={loading}
                    disabled={formData.MobileNumber.length < 10 ? true : false}
                    onClick={handleSendRegisterCode}>
                    Send Verification code
                  </LoadingButton>
                </>
                :
                <>
                  <Typography
                    variant='body2'
                    textAlign={"center"}>
                    Enter verification code sent of +{formData.MobileCountryCode}-{formData.MobileNumber}
                  </Typography>
                  <Box
                    className="flexCenterSBRow"
                    gap={"15px"}>
                    <OTPInput
                      value={formData.Code}
                      onChange={(e) => {
                        setFormData({ ...formData, Code: e})
                      }}
                      shouldAutoFocus={true}
                      numInputs={4}
                      isInputNum={true}
                      isInputSecure={true}
                      containerStyle={"auth__otp"}
                      inputStyle={"auth__otp-input"}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props} />
                      }
                    />
                  </Box>
                  {
                    allowResendCode
                    ?
                    <Link
                      pt={"5px"}
                      onClick={() => handleResendRegisterCode()}>
                      Resend code
                    </Link>
                    :
                    <Typography
                      fontSize={"17px"}
                      fontWeight={"600"}>
                      Resend code in&nbsp;
                      <Countdown
                        date={startTime}
                        renderer={({ hours, minutes, seconds, completed }) => {
                            return <span>{seconds}</span>;
                          }
                        }
                        onComplete={() => {
                          setAllowResendCode(true);
                        }} />
                      &nbsp;seconds
                    </Typography>
                  }
                  <LoadingButton
                    variant='contained' 
                    loading={loading}
                    disabled={formData.Code.length < 4 ? true : false}
                    onClick={handleRegister}>
                    Register
                  </LoadingButton>
                </>
              }
              <Link
                textAlign={"center"}
                onClick={() => navigate("/auth")}>
                Already have an account?
              </Link>
              <Typography
                variant='caption'
                sx={{ color: "grey" }}
                textAlign={"center"}>
                By signing up you agree to our&nbsp;
                <a href='https://lusid.ai/terms-and-conditions' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                &nbsp;and &nbsp;
                <a href='https://lusid.ai/privacy-policy' target="_blank" rel="noopener noreferrer">Terms & Conditions.</a>
              </Typography>
              {/* <Button
                variant='text'
                onClick={() => navigate("/auth")}>
                Login
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default RegisterPage;