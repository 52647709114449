import React from 'react';

import { 
  Box,
  Chip, 
  Tooltip
} from '@mui/material';

import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

import "../../global.scss";

const BucketTypeChip = (props) => {
  return (
    <>
      {
        props.children === "ONE_TIME"
        ?
        <></>
        :
        <Tooltip 
          title="Wallet will refresh with given amount.">
          <Chip 
            { ...props }
            label={
              <Box className="flexCenter_Row">
                <CachedOutlinedIcon fontSize='small' sx={{ color: "grey", marginLeft: "-5px" }}/>
                {
                  props.children === "RECURRING_WEEKLY"
                  ? "Weekly"
                  : props.children === "RECURRING_BIWEEKLY"
                  ? "Bi-weekly"
                  : props.children === "RECURRING_MONTHLY"
                  ? "Monthly"
                  : props.children === "RECURRING_QUATERLY"
                  ? "Quarterly"
                  : props.children === "RECURRING_YEARLY"
                  ? "Yearly"
                  : ""  
                }
              </Box>
            }
            />
        </Tooltip>
      }
    </>
  )
}

export default BucketTypeChip;