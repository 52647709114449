import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Modal, 
  Skeleton, 
  Step, 
  StepLabel, 
  Stepper, 
  Switch, 
  TextField, 
  ToggleButton, 
  ToggleButtonGroup, 
  Tooltip, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';
import MasterCardLogo from '../../assets/icons/mastercard-logo.svg';

import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { 
  GetCards 
} from '../../redux/actions/cardActions';
import { 
  GetCircleDetails 
} from '../../redux/actions/circleActions';
import { 
  CreateWallet, 
  GetWallets 
} from '../../redux/actions/associationActions';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  ForUserId: "",
  Alias: "",
  RealCardId: "",
  Amount: "",
  Expiry: "",
  Domestic: true,
  International: false,
  AllowValidTill: true
}

const CreateBucketsModal = ({open, setOpen, title, page, rowsPerPage, userId}) => {
  const dispatch = useDispatch();
  const card = useSelector(state => state.card);
  const circle = useSelector(state => state.circle);
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  const [cardsList, setCardsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [usersList, setUsersList] = useState([]);

  const [selectedSource, setSelectedSource] = useState(null);
  const [walletType, setWalletType] = useState(1);
  const [oneTimeBucketRange, setOneTimeBucketRange] = useState(1);
  const [customOneTimeBucketType, setCustomOneTimeBucketType] = useState(1);
  const [customOneTimeBucketValue, setCustomOneTimeBucketValue] = useState(null);

  const [customWalletRange, setCustomWalletRange] = useState(2);
  const [customValidTillType, setCustomValidTillType] = useState(1);
  const [customValidTillValue, setCustomValidTillValue] = useState(1);

  // ------------------------- stepper ---------------------------
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ['Source', 'Details', 'Controls'];

  const handleClose = () => {
    setOpen(false);
    setSelectedSource(null);
    setWalletType(1);
    setCustomWalletRange(2);
    setFormData(Data);
    setActiveStep(0);
    setCustomValidTillType(1);
    setCustomValidTillValue(1);
    setOneTimeBucketRange(1);
    setCustomOneTimeBucketType(1);
    setCustomOneTimeBucketValue(null);
  }

  useEffect(() => {
    if(open){
      dispatch(GetCircleDetails(setContentLoading));
      dispatch(GetCards(1, 100, setContentLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatUsersList(circle.circleDetails.Records || []);
    } catch (err) { }
  }, [circle]);

  useEffect(() => {
    try {
      formatCardsList(card.cardsList.Records || []);
    } catch (err) { }
  }, [card]);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleWalletCreate = () => {
    let obj = {
      ForUserId: userId,
      Alias: formData.Alias,
      RealCardId: selectedSource,
      Amount: formData.Amount,
      Expiry: new Date(Date.now() + calculateExpiryTime()).toISOString(),
      Type: getBucketType(),
      Controls: {
        Usage: {
          Domestic: formData.Domestic,
          International: formData.International,
        },
        VCN: {
          AllowValidFor: formData.AllowValidTill,
          ValidFor: calcaluteValidTillTime() || 86400000,
        }
      }
    }
    
          // {
      //   Usage: {
      //     Domestic: true,
      //     International: false,
      //   },
      //   VCN: {
      //     ValidFor: 15,
      //   },
      // };

    dispatch(CreateWallet(obj, setLoading)).then(() => {
      dispatch(GetWallets(page+1, rowsPerPage, setLoading)).then(() => {
        handleClose();
      })
    })

  }

  // ------------------------- list formatter --------------------
  const formatUsersList = (list) => {
    setUsersList(list);
  }

  const formatCardsList = (list) => {
    setCardsList(list.filter(i => i.Status === true));
  }

  // ------------------------- miscellaneous ---------------------
  const calculateExpiryTime = () => {
    let additionalTime = 0;

    if(walletType === 1){
      // one time bucket
      if(oneTimeBucketRange === 1){
        // 1 day
        additionalTime = 24 * 60 * 60 * 1000
      }else if(oneTimeBucketRange === 2){
        // 1 month
        additionalTime = 30 * 24 * 60 * 60 * 1000
      }else if(oneTimeBucketRange === 3){
        // custom time
        if(customOneTimeBucketType === 1){
          // custom minutes
          additionalTime = parseInt(customOneTimeBucketValue) * 60 * 1000
        }else if(customOneTimeBucketType === 2){
          // custom hours
          additionalTime = parseInt(customOneTimeBucketValue) * 60 * 60 * 1000
        }else if(customOneTimeBucketType === 3){
          // custom days
          additionalTime = parseInt(customOneTimeBucketValue) * 24 * 60 * 60 * 1000
        }else if(customOneTimeBucketType === 4){
          // custom months
          additionalTime = parseInt(customOneTimeBucketValue) * 30 * 24 * 60 * 60 * 1000
        }else{
          toast.error("Something went wrong");
        }
      }else{
        toast.error("Something went wrong");
      }
    }else if(walletType === 2){
      // recurring bucket
      if(customWalletRange === 1){
        // weekly - give 1 week expiry
        additionalTime = 7 * 24 * 60 * 60 * 1000
      }else if(customWalletRange === 2){
        // monthly - give 1 month expiry
        additionalTime = 30 * 24 * 60 * 60 * 1000
      }else if(customWalletRange === 3){
        // yearly - give 1 year expiry
        additionalTime = 356 * 24 * 60 * 60 * 1000
      }else{
        toast.error("Something went wrong");
      }
    }

    return additionalTime;
  }

  const calcaluteValidTillTime = () => {
    let timeStamp = "";

    if(customValidTillType === 1){
      // minutes
      timeStamp = customValidTillValue * 60 * 1000;
    }else if(customValidTillType === 2){
      // hours
      timeStamp = customValidTillValue * 60 * 60 * 1000;
    }else if(customValidTillType === 3){
      // days
      timeStamp = customValidTillValue * 24 * 60 * 60 * 1000;
    }else{
      toast.error("Something went wrong")
    }

    return timeStamp;
  }

  const getBucketType = () => {
    let type = "";
    if(walletType === 1){
      // one-time buckket
      type = "ONE_TIME";
    }else if(walletType === 2 && customWalletRange === 1){
      // recurring - weekly
      type = "RECURRING_WEEKLY";
    }else if(walletType === 2 && customWalletRange === 2){
      // recurring - monthly
      type = "RECURRING_MONTHLY";
    }else if(walletType === 2 && customWalletRange === 3){
      // recurring - yearly
      type = "RECURRING_YEARLY";
    }else{
      //default
      type = "ONE_TIME";
    }

    return type;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          width={"100vw"} height={"100vh"}
          sx={{ borderRadius: "0px" }}
          className="modal flex_SBColumn">
          <Box>
            <Box width={"100%"}
              className="flexCenter_Row"
              sx={{ margin: "15px 15px 30px 15px", gap: "15px" }}>
              <IconButton
                onClick={handleClose}>
                <ClearIcon 
                  sx={{ color: "grey", fontSize: "40px" }}/>
              </IconButton>
              <Typography variant='h5' sx={{ color: style["secondary"] }}>
                {title}
              </Typography>
            </Box>
            <Box
              height={"100%"}
              className="flexCenterCenterRow"
              padding={"20px"}>
              <Box
                sx={{
                  width: "100%", 
                  maxWidth: "md",
                  margin: "auto", 
                  height: "70vh" }}
                  className="flex_SBColumn">
                <Box>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {
                    activeStep === 0
                    ?
                    <>
                      <Typography variant='subtitle1' textAlign={"center"}
                        mt={"20px"} mb={"40px"}>
                        Select source for your wallet
                      </Typography>
                      { 
                        contentLoading
                        ?
                        <>
                          <Skeleton
                            variant="rectangular" 
                            animation="wave"
                            width={"220px"} height={"38px"} 
                            mb={"20px"}
                            sx={{ borderRadius: "2px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                          <Box className="flexCenterSBRow" gap={"16px"}>
                            <Skeleton
                              variant="rectangular" 
                              animation="wave"
                              width={"300px"} height={"120px"} 
                              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                            <Skeleton
                              variant="rectangular" 
                              animation="wave"
                              width={"300px"} height={"120px"} 
                              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                            <Skeleton
                              variant="rectangular" 
                              animation="wave"
                              width={"300px"} height={"120px"} 
                              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                          </Box>
                        </>
                        :
                          cardsList.length === 0
                          ?
                          <Box className="flexCenterCenterRow" height={"200px"}>
                            <Typography>No real card found</Typography>
                          </Box>
                          :
                            <Box>
                              <Typography variant='h6' mb={"20px"}>
                                Real Cards
                              </Typography>
                              <Grid container
                                gap={2}>
                                {
                                  cardsList.map((item, index) => (
                                    <Grid item key={index} 
                                      className={`vcn__creditcard ${selectedSource === item.Id && 'vcn__creditcard-selected'}`}
                                      lg={3.8} md={3.8} sm={5.8} xs={12}
                                      onClick={() => setSelectedSource(item.Id)}>
                                      <Box className="flexCenterSBRow">
                                        <Typography variant='subtitle2'>{item.Alias}</Typography>
                                        <Typography>{item.Currency}</Typography>
                                      </Box>
                                      <Box className="flexCenterSBRow">
                                        <Typography>xxxx&nbsp;xxxx&nbsp;xxxx&nbsp;{item.Last4Digits}</Typography>
                                      </Box>
                                      <Box className="flexCenterFERow" mt={"5px"}>
                                        <img
                                          src={MasterCardLogo}
                                          alt='mastercard-logo'
                                          style={{ width: "45px" }}/>
                                      </Box>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                            </Box>
                        }
                    </>
                    :
                      activeStep === 1
                      ?
                      <>
                        <Typography variant='subtitle1' textAlign={"center"}
                          mt={"20px"} mb={"40px"}>
                          Select wallet type and details
                        </Typography>
                        <Box 
                          width={"100%"}
                          maxWidth={"400px"}
                          margin={"auto"}
                          className="flexCenterCenterColumn" 
                          gap={"20px"}>
                          <Box 
                            width={"100%"}
                            mb={"10px"}>
                            <Box className="flexCenter_Row" gap={"10px"} mb="10px">
                              <Typography variant='subtitle1'>
                                Wallet type
                              </Typography>
                              <Tooltip 
                                variant="filled"
                                title={
                                  <>
                                    {"There are 2 types of wallets "}<br />
                                    <b>{"One-time wallet: LOREM IPSUM"}</b><br />
                                    <b>{"Recurring wallet: LOREM IPSUM"}</b>
                                  </>
                                }>
                                <InfoOutlinedIcon fontSize='small' sx={{ color: "grey"}}/>
                              </Tooltip>
                            </Box>
                            <Box className="flexCenterSBRow" gap={"20px"}>
                              <ToggleButtonGroup
                                color="primary"
                                value={walletType+""}
                                fullWidth
                                exclusive
                                onChange={(e) => setWalletType(parseInt(e.target.value))}
                                aria-label="Platform"
                              >
                                <ToggleButton value={"1"}>One time</ToggleButton>
                                <ToggleButton value={"2"}>Recurring</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>


                            {
                              walletType === 1
                              &&
                              <Box mt={"20px"}>
                                <Box className="flexCenter_Row" gap={"10px"} mb="10px">
                                  <Typography variant='subtitle2'>
                                    Bucket validity
                                  </Typography>
                                  <InfoOutlinedIcon fontSize='small' sx={{ color: "grey"}}/>
                                </Box>

                                <Box className="flexCenterSBRow"
                                  gap="20px">
                                  <ToggleButtonGroup
                                    color="primary"
                                    value={oneTimeBucketRange+""}
                                    fullWidth
                                    exclusive
                                    onChange={(e) => setOneTimeBucketRange(parseInt(e.target.value))}
                                    aria-label="Platform"
                                  >
                                    <ToggleButton value={"1"}>1 Day</ToggleButton>
                                    <ToggleButton value={"2"}>1 Month</ToggleButton>
                                    <ToggleButton value={"3"}>Custom</ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                              </Box>
                            }

                            {
                              walletType === 1 && oneTimeBucketRange === 3
                              &&
                              <Box mt={"20px"}>
                                <Box className="flexFSSBRow" gap={"20px"}>
                                  <TextField
                                    select
                                    sx={{ width: "40%" }}
                                    value={customOneTimeBucketType}
                                    onChange={(e) => {
                                      setCustomOneTimeBucketType(e.target.value);
                                    }}>
                                    <MenuItem 
                                      value={1}>
                                      Minutes
                                    </MenuItem>
                                    <MenuItem 
                                      value={2}>
                                      Hours
                                    </MenuItem>
                                    <MenuItem 
                                      value={3}>
                                      Days
                                    </MenuItem>
                                    <MenuItem 
                                      value={4}>
                                      Months
                                    </MenuItem>
                                  </TextField>
                                  <TextField 
                                    sx={{ width: "60%" }}
                                    variant='outlined'
                                    name='flexFSSBRow'
                                    label='Value'
                                    placeholder={
                                      customOneTimeBucketType === 1 ? "Number of minutes" :
                                        customOneTimeBucketType === 2 ? "Number of hours" :
                                          customOneTimeBucketType === 3 ? "Number of days" :
                                            customOneTimeBucketType === 4 ? "Number of months" : ""
                                    }
                                    fullWidth={true}
                                    value={customOneTimeBucketValue}
                                    inputProps={{ maxLength: 64 }}
                                    type="number"
                                    onChange={(e) => {
                                      let str = e.target.value.replace(".", "").replace("e", "");
                                      setCustomOneTimeBucketValue(str);
                                    }}
                                    error={
                                      customOneTimeBucketType === 1 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 60 ? false : true :
                                        customOneTimeBucketType === 2 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 24 ? false : true :
                                          customOneTimeBucketType === 3 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 365 ? false : true : 
                                            customOneTimeBucketType === 4 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 12 ? false : true : false
                                    }
                                    helperText={
                                      customOneTimeBucketType === 1 ? "Valid range is 1 to 60" :
                                        customOneTimeBucketType === 2 ? "Valid range is 1 to 24" :
                                          customOneTimeBucketType === 3 ? "Valid range is 1 to 365" : 
                                            customOneTimeBucketType === 4 ? "Valid range is 1 to 12" : ""
                                    }
                                    />
                                </Box>
                              </Box>
                            }

                            {
                              walletType === 2
                              &&
                              <Box mt={"20px"}>
                                <Box className="flexCenter_Row" gap={"10px"} mb="10px">
                                  <Typography variant='subtitle2'>
                                    Recurring interval
                                  </Typography>
                                  <InfoOutlinedIcon fontSize='small' sx={{ color: "grey"}}/>
                                </Box>

                                <Box className="flexCenterSBRow"
                                  gap="20px">
                                  <ToggleButtonGroup
                                    color="primary"
                                    value={customWalletRange+""}
                                    fullWidth
                                    exclusive
                                    onChange={(e) => setCustomWalletRange(parseInt(e.target.value))}
                                    aria-label="Platform"
                                  >
                                    {/* <ToggleButton value={"1"}>Weekly</ToggleButton> */}
                                    <ToggleButton value={"2"}>Monthly</ToggleButton>
                                    <ToggleButton value={"3"}>Yearly</ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                              </Box>
                            }
                          </Box>

                          <Box 
                            width={"100%"}>
                            <Typography variant='subtitle1' mb="10px">
                              Details
                            </Typography>
                            <Box 
                              className="flexCenterCenterColumn" 
                              gap={"20px"}>
                              <TextField 
                                variant='outlined'
                                name='Alias'
                                label='Wallet name'
                                placeholder='Enter a name for your wallet'
                                fullWidth={true}
                                autoFocus={true}
                                value={formData.Alias}
                                inputProps={{ maxLength: 32 }}
                                onChange={handleChange}/>
                              <TextField 
                                variant='outlined'
                                type='number'
                                name='Amount'
                                label='Amount'
                                placeholder='Total amount will be to this wallet'
                                fullWidth={true}
                                inputProps={{ maxLength: 8 }}
                                value={formData.Amount}
                                onChange={handleChange}
                                helperText={walletType === 2 
                                  ? "Wallet will refresh with this amount after selected interval." 
                                  : ""
                                }/>
                            </Box>
                          </Box>

                        </Box>
                      </>
                      :
                      <>
                        <Typography variant='subtitle1' textAlign={"center"}
                          mt={"20px"} mb={"40px"}>
                          Set controls for this bucket
                        </Typography>
                        <Box 
                          width={"100%"}
                          maxWidth={"400px"}
                          margin={"auto"}
                          className="flexCenterCenterColumn" 
                          gap={"20px"}>
                          <Box  width={"100%"}>
                            <Typography variant='subtitle1' mb="10px">
                              Usage controls
                            </Typography>
                            <Box
                              className="flexCenterSBRow"
                              mb={"10px"}>
                              <Typography variant='body1'>Domestic usage</Typography>
                              <Switch 
                                checked={formData.Domestic}
                                onChange={(e) => {
                                  let obj = { target: { name: "Domestic", value: e.target.checked } }
                                  handleChange(obj);
                                }}
                                />
                            </Box>
                            <Box
                              className="flexCenterSBRow"
                              mb={"40px"}>
                              <Typography variant='body1'>International usage</Typography>
                              <Switch 
                                checked={formData.International}
                                onChange={(e) => {
                                  let obj = { target: { name: "International", value: e.target.checked } }
                                  handleChange(obj);
                                }}
                                />
                            </Box>
                            <Typography variant='subtitle1' mb="10px">
                              Virtual card controls
                            </Typography>
                            <Box
                              className="flexCenterSBRow"
                              mb={"10px"}>
                              <Typography variant='body1'>Allow circle member to set virtual card expiry</Typography>
                              <Switch 
                                checked={formData.AllowValidTill}
                                onChange={(e) => {
                                  let obj = { target: { name: "AllowValidTill", value: e.target.checked } }
                                  handleChange(obj);
                                }}
                                />
                            </Box>
                            {
                              formData.AllowValidTill === false
                              &&
                              <Box mb={"10px"}>
                                <Typography variant='body1' mb={"10px"}>Default virtual card expiry</Typography>
                                <Box className="flexFSSBRow" gap={"20px"}>
                                  <TextField
                                    select
                                    sx={{ width: "40%" }}
                                    value={customValidTillType}
                                    onChange={(e) => {
                                      setCustomValidTillType(e.target.value);
                                    }}>
                                    <MenuItem 
                                      value={1}>
                                      Minutes
                                    </MenuItem>
                                    <MenuItem 
                                      value={2}>
                                      Hours
                                    </MenuItem>
                                    <MenuItem 
                                      value={3}>
                                      Days
                                    </MenuItem>
                                  </TextField>
                                  <TextField 
                                    sx={{ width: "60%" }}
                                    variant='outlined'
                                    name='flexFSSBRow'
                                    label='Value'
                                    placeholder={
                                      customValidTillType === 1 ? "Number of minutes" :
                                        customValidTillType === 2 ? "Number of hours" :
                                          customValidTillType === 3 ? "Number of days" : ""
                                    }
                                    fullWidth={true}
                                    value={customValidTillValue}
                                    inputProps={{ maxLength: 64 }}
                                    type="number"
                                    onChange={(e) => {
                                      let str = e.target.value.replace(".", "").replace("e", "");
                                      setCustomValidTillValue(str);
                                    }}
                                    error={
                                      customValidTillType === 1 ? customValidTillValue >= 1 && customValidTillValue <= 60 ? false : true :
                                        customValidTillType === 2 ? customValidTillValue >= 1 && customValidTillValue <= 24 ? false : true :
                                          customValidTillType === 3 ? customValidTillValue >= 1 && customValidTillValue <= 7 ? false : true : false
                                    }
                                    helperText={
                                      customValidTillType === 1 ? "Valid range is 1 to 60" :
                                        customValidTillType === 2 ? "Valid range is 1 to 24" :
                                          customValidTillType === 3 ? "Valid range is 1 to 7" : ""
                                    }
                                    />
                                </Box>
                              </Box>
                            }
                          </Box>
                        </Box>
                      </>
                  }
                </Box>
                <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
                  {
                    activeStep > 0 &&
                    <Button variant='outlined'
                      onClick={handleBack}>
                      Back
                    </Button>
                  }
                  <LoadingButton 
                    variant={activeStep < 2 ? 'text' : 'contained'}
                    loading={loading}
                    disabled={
                        activeStep === 0
                        ? selectedSource === null ? true : false
                        :
                          activeStep === 1 
                          ? (formData.Amount > 0 && formData.Alias !== ""
                            && (oneTimeBucketRange !== 3 || 
                              !(customOneTimeBucketType === 1 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 60 ? false : true :
                                customOneTimeBucketType === 2 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 24 ? false : true :
                                  customOneTimeBucketType === 3 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 365 ? false : true : 
                                    customOneTimeBucketType === 4 ? customOneTimeBucketValue >= 1 && customOneTimeBucketValue <= 12 ? false : true : false))
                             ) ? false : true
                          : 
                            formData.AllowValidTill === true 
                            ? false
                            : 
                            (customValidTillType === 1 ? customValidTillValue >= 1 && customValidTillValue <= 60 ? false : true :
                            customValidTillType === 2 ? customValidTillValue >= 1 && customValidTillValue <= 24 ? false : true :
                            customValidTillType === 3 ? customValidTillValue >= 1 && customValidTillValue <= 7 ? false : true : false)
                    }
                    onClick={() => {
                      if(activeStep === 2){
                        handleWalletCreate();
                      }else{
                        handleNext();
                      }
                    }}>
                    {activeStep < 2 ? 'Next' : 'Create'}
                  </LoadingButton>
                </Box> 
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateBucketsModal