import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../components/utils/TablePaginationActions';
import TableLoading from '../../components/loaders/TableLoading';
import TopBar from '../../components/topBar/TopBar';

import WalletTwoToneIcon from '@mui/icons-material/WalletTwoTone';

import { 
  GetCircleDetails, 
  RemoveUserFromCircle 
} from '../../redux/actions/circleActions';

import AddMemberModal from './AddMemberModal';
import PendingRequestsModal from './PendingRequestsModal';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import CreateBucketModal from './CreateBucketsModal';
import BucketsModal from './BucketsModal';

import style from "../../global.scss";
import "./styles.scss";

const CirclesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.circle);
  const [loading, setLoading] = useState(true);
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [pendingRequestsModalOpen, setPendingRequestsModalOpen] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [createBucketsModalOpen, setCreateBucketsModalOpen] = useState(false);
  const [bucketsModalOpen, setBucketsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [circleList, setCircleList] = useState([]);
  const [circleTotal, setCircleTotal] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);

  // --------------------- table pagination ----------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    dispatch(GetCircleDetails(setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      formatCircleList(state.circleDetails.Records || []);
      setCircleTotal(state.circleDetails.Total || 0);
    } catch (err) {}
  }, [state]);  

  // ------------------------- list formatter -------------------------
  const formatCircleList = (list) => {
    setCircleList(list);
  }
  
  const handleUserRemove = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(RemoveUserFromCircle(deleteId, setDeleting)).then(() => {
      dispatch(GetCircleDetails(setLoading))
    })
  }

  // ------------------------- miscellaneous -------------------------
  const markMobileNumber = (mobileNumber) => {
    return (
      mobileNumber.substring(0, 2) +
      "******" +
      mobileNumber.substring(mobileNumber.length - 4, mobileNumber.length)
    );
  }

  return (  
    <Box width={"100%"}>

      <AddMemberModal 
        open={addMemberModalOpen}
        setOpen={setAddMemberModalOpen}
        title={"Search Member"}
        />

      <PendingRequestsModal 
        open={pendingRequestsModalOpen}
        setOpen={setPendingRequestsModalOpen}
        title={"Pending Requests"}
        />
      
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleUserRemove}
        />

      <CreateBucketModal 
        open={createBucketsModalOpen}
        setOpen={setCreateBucketsModalOpen}
        title={modalTitle}
        page={page}
        rowsPerPage={rowsPerPage}
        userId={selectedUser}
        />

      <BucketsModal 
        open={bucketsModalOpen}
        setOpen={setBucketsModalOpen}
        title={modalTitle}
        id={update}
        />

      <TopBar 
        title={"Circle members"}
        button1Text={"Add Member"}
        button1OnClick={() => {
          setAddMemberModalOpen(!addMemberModalOpen);
        }}
        />

      {
        loading
        ?
        <TableLoading />
        :
          circleList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No circle member found</Typography>
          </Box>
          :
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Added on</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                circleList.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ backgroundColor: row.Status === false && "#faf1f1" }}>
                    <TableCell>{row.User.Name || "NA"}</TableCell>
                    <TableCell>{row.User.Mobile ? markMobileNumber(row.User.Mobile) : "NA"}</TableCell>
                    <TableCell>{row.ConnectedOn ? new Date(row.ConnectedOn).toDateString().slice(4) : "NA"}</TableCell>
                    <TableCell sx={{ pt: 1, pb: 1, width: "350px" }}>
                      <Box className="flexCenter_Row" gap="30px">
                        <Tooltip title={"Create wallet for " + row.User.Name || "NA"}>
                          <Button variant='text'
                            onClick={() => {
                              setSelectedUser(row.User.Id);
                              setModalTitle("Create wallet for " + row.User.Name || "NA");
                              setCreateBucketsModalOpen(!createBucketsModalOpen);
                            }}>
                            Create Wallet
                          </Button>
                        </Tooltip>

                        <Tooltip title={"View wallets for " + row.User.Name || "NA"}>
                          <IconButton
                            onClick={() => { 
                              setUpdate(row.User.Id);
                              setModalTitle("Wallets given to " + row.User.Name || "NA");
                              setBucketsModalOpen(!bucketsModalOpen);
                            }}>
                            <WalletTwoToneIcon sx={{ color: style['secondary_light']}}/>
                          </IconButton>
                        </Tooltip>

                        {/* {
                          deleteId === row.Id && deleting
                          ?
                          <CircularProgress size={"24px"}/>
                          :
                          <Tooltip title={"Remove " + row.User.Name + " from circle"}>
                            <IconButton
                              onClick={() => {
                                setDeleteId(row.Id);
                                setModalTitle("Removing " + row.User.Name);
                                setDeleteConfirmationModalOpen(!deleteConfirmationModalOpen);
                              }}>
                              <DeleteTwoToneIcon sx={{ color: style['secondary_light']}}/>
                            </IconButton>
                          </Tooltip>
                        } */}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]} 
                  colSpan={10}
                  count={circleTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default CirclesPage