import { createTheme } from "@mui/material";

import styles from "../global.scss";

export const theme = createTheme({
    typography: {
        // fontFamily: "urbanist",
        fontFamily: "Plus Jakarta Sans",
        h1: {
            fontWeight: 900,
            fontSize: "72px",
            color: styles["secondary_dark"]
        },
        h2: {
            fontWeight: 800,
            fontSize: "56px",
            color: styles["secondary_dark"]
        },
        h3: {
            fontWeight: 700,
            fontSize: "38px",
            color: styles["secondary_dark"]
        },
        h4: {
            fontWeight: 600,
            fontSize: "28px",
            color: styles["secondary_dark"]
        },
        h5: {
            fontWeight: 600,
            fontSize: "24px",
            color: styles["secondary_dark"]
        },
        h6: {
            fontWeight: 600,
            fontSize: "18px",
            color: styles["secondary_dark"]
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: "16px",
            color: styles["secondary_dark"]
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "15px",
            color: styles["secondary_dark"]
        },
        body1: {
            fontWeight: 400,
            fontSize: "14px", 
            color: styles["secondary_dark"]
        },
        body2: {
            fontWeight: 400,
            fontSize: "13px",
            color: styles["secondary_dark"]
        },
        button: {
            fontWeight: 400,
            fontSize: "14px",
            color: styles["secondary_dark"]
        },
        caption: {
            fontWeight: 400,
            fontSize: "12px",
            color: styles["secondary_dark"]
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: "600",
                    borderRadius: "12px",
                    minWidth: "120px",
                    minHeight: "48px",
                },
                text: {
                    backgroundColor: styles["primary_light"],
                    color: styles["secondary_dark"],
                    "&:hover": {
                        backgroundColor: styles["primary"]
                    }
                },
                contained: {
                    backgroundColor: styles["secondary"],
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["secondary_light"]
                    }
                },
                outlined: {
                    borderColor: styles["secondary_dark"],
                    color: styles["secondary_dark"],
                    "&:hover": {
                        backgroundColor: styles["primary_light"]
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root:{
                    color: "grey"
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "grey"
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: "12.5px",
                    color: "grey"
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    // pointerEvents: 'none',
                },
                paper: {
                    borderRadius: "12px",
                    // pointerEvents: "auto",
                },
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: "15px",
                    cursor: 'pointer'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: "grey",
                    backgroundColor: "#fafafa",
                    borderColor: "#fafafa",
                    padding: "8px 16px",
                },
                body: {
                    borderColor: "#f1f1f1",
                    padding: "8px 16px",
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fafafa"
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: 6,
                },
                thumb: {
                    backgroundColor: styles["secondary_light"],
                    boxShadow: 'none',
                    width: 18,
                    height: 18,
                    margin: 1,
                },
                track: {
                    border: "1.5px solid",
                    borderColor: styles["secondary"],
                    backgroundColor: "white",
                    borderRadius: 26 / 2,
                    '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 18,
                        height: 18,
                    },
                },
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    transition: "all .3s",
                    borderRadius: "12px",
                    borderWidth: "0px",
                    backgroundColor: styles["primary_light"],
                    color: styles["secondary"],
                    "&:hover": {
                        backgroundColor: styles["primary"],
                        color: styles["secondary"],
                    },
                    '&.Mui-selected': {
                        backgroundColor: styles["secondary"],
                        color: styles["primary_light"],
                        "&:hover": {
                            backgroundColor: styles["secondary_light"],
                        color: styles["primary_light"],
                        },
                    }
                },
            }
        },
    }
})