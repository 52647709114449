import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as actionTypes from "../../redux/actionTypes";

const ErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.other);

  useEffect(() => {
    try {
      console.log(state);
      if(state.errors !== null) errorParser(state.errors);
      if(state.crashes !== null) crashParser(state.crashes);
    } catch(err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // ------------------- testing functions -------------------
  function testAccountDeactive(data) {
    let codeList = [
      "LOGIN_USER_3",
      "GET_PROFILE_3",
      "UPD_NAMES_3"
    ];

    if(codeList.includes(data.errors[0])){
      return true;
    } else {
      return false;
    }
  }

  const errorParser = (data) => {
    if(testAccountDeactive(data)){
      dispatch({ type: actionTypes.LOG_OUT });
      navigate("/message");
    } 
  
    if(data.errors[0] === "SEND_LOGIN_CODE_1") navigate("/register");
    if(data.errors[0] === "SEND_REG_CODE_1") navigate("/auth");

    // errors will be resolved at first instance.
    dispatch({ type: actionTypes.CLEAR_ERRORS });
  }

  const crashParser = (data) => {
    if(testAccountDeactive(data)){
      dispatch({ type: actionTypes.LOG_OUT });
      navigate("/message");
    } 

    if(data.errors[0] === "LOGIN_USER_4") toast.error(data.message);

    if(typeof(data) === 'string'){
      toast.error(data);
    }
    // same as errors, crashes will also be resolved at first instance.
    dispatch({ type: actionTypes.CLEAR_CRASHES });
  }

}

export default ErrorHandler;