import * as actionTypes from "../actionTypes.js";

const initialState = {
  profile: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE:
      return { ...state, profile: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;
   
    default:
      return state;
  }
};

export default profileReducer;