import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import { 
  Box,
	Button,
	Container, 
	Typography 
} from '@mui/material';

import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import Navigation from "../components/navigation/Navigation";
import ErrorHandler from '../components/errorHandler/ErrorHandler';

//routes
import AuthPage from '../views/authPage/AuthPage';
import RegisterPage from '../views/authPage/RegisterPage';
import LoginMessagePage from '../views/miscellaneousPage/LoginMessagePage';

import CardsPage from '../views/cardsPage/CardsPage';
import VirtualCardsPage from '../views/virtualCardsPage/VirtualCardsPage';
import CirclesPage from '../views/circlesPage/CirclesPage';
import WalletsPage from '../views/bucketsPage/BucketsPage';
import ProfilePage from '../views/profilePage/ProfilePage';
import SetupFlow from '../views/profilePage/SetupFlow';

const MainRouter = () => {

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path='/auth' element={<AuthPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/message' element={<LoginMessagePage />} />
          {/* <Route path='/forgot-password' element={<ForgotPasswordPage />} /> */}
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path='/' element={<Navigation />}>
            <Route path='' element={<VirtualCardsPage />} />
            <Route path='buckets/:userId/:walletId' element={<VirtualCardsPage />} />
            <Route path='cards' element={<CardsPage />} />
            <Route path='circles' element={<CirclesPage />} />
            <Route path='buckets' element={<WalletsPage />} />
          </Route>
          <Route path='/setup' element={<SetupFlow />} />
          <Route path='/profile' element={<ProfilePage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <ErrorHandler />
    </Router>
  )
}

export default MainRouter;

// 404 page
const NotFoundPage = () => {
  const navigate = useNavigate();
  return(
		<Container maxWidth="lg">
      <Box sx={{ height: "calc(100vh)", gap: "40px" }} 
        className="flexCenterCenterColumn">
			  <Typography variant='h4'>404 - Page not found</Typography>
        <Button
          variant='contained'
          onClick={() => navigate("/")}>
          Go Back
        </Button>
      </Box>
		</Container>
  )
}