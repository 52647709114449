import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal, 
  Typography
} from '@mui/material';

import { 
  GetWalletDetails, 
} from '../../redux/actions/associationActions';

import style from "../../global.scss";
import "./styles.scss";

const BucketInfoModal = ({open, setOpen, title, id}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.associate);
  const [loading, setLoading] = useState(true);

  const [walletInfo, setWalletInfo] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  }

  useEffect(() => { 
    if(open) {
      dispatch(GetWalletDetails(id, setLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatWalletObj(state.myWalletDetails || null);
    } catch (err) {}
  }, [state]);

  // -------------------- object formatter --------------------
  const formatWalletObj = (obj) => {
    setWalletInfo(obj);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box sx={{ maxWidth: "sm", margin: "10px" }}
        className="confirm flex_SBColumn">
          <Box width={"100%"}
            sx={{ padding: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }} mb={'30px'}>
              {title}
            </Typography>
            {
              loading
              ?
              <Box height={"320px"} className="flexCenterCenterRow">
                <CircularProgress />
              </Box>
              :
                walletInfo === null
                ?
                <Box height={"320px"} className="flexCenterCenterRow">
                  <Typography variant='subtitle1'>Something went wrong</Typography>
                </Box>
                :
                <Box height={"320px"}>
                  <Box mb={"30px"}>
                    <Typography variant='subtitle1'>Usage Controls</Typography>
                    <Box className="flexCenterSBRow" mb={'10px'} 
                      sx={{ backgroundColor: "#fafafa", padding: "16px 20px", borderRadius: "12px" }}>
                      <Typography>Domestic</Typography>
                      {
                        walletInfo.Usage_Controls.Domestic
                        ? <Typography color={'green'}>Allowed</Typography>
                        : <Typography color={"error"}>Not Allowed</Typography>
                      }
                    </Box>
                    <Box className="flexCenterSBRow" mb={'30px'} 
                      sx={{ backgroundColor: "#fafafa", padding: "16px 20px", borderRadius: "12px" }}>
                      <Typography>International</Typography>
                      {
                        walletInfo.Usage_Controls.International
                        ? <Typography color={"green"}>Allowed</Typography>
                        : <Typography color={"error"}>Not Allowed</Typography>
                      }
                    </Box>
                    <Typography variant='subtitle1'>Virtual Card Controls</Typography>
                    <Box className="flexCenterSBRow"
                      sx={{ backgroundColor: "#fafafa", padding: "16px 20px", borderRadius: "12px" }}>
                      <Typography>Valid For</Typography>&nbsp;
                      <Typography>{walletInfo.Virtual_Card_Controls.ValidFor}</Typography>
                    </Box>
                  </Box>
                  <Typography color={"grey"} variant='body2'>
                    <i>Ask your wallet provider to change wallet settings.</i>
                  </Typography>
                </Box>
            }
          </Box>
          <Divider
          sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default BucketInfoModal;