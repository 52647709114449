import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal, 
  Typography
} from '@mui/material';
import { 
  Timeline, 
  TimelineConnector, 
  TimelineContent, 
  TimelineDot, 
  TimelineItem, 
  TimelineOppositeContent, 
  TimelineSeparator 
} from '@mui/lab';

import useCurrencyService from '../../components/utils/CurrencyService';

import MasterCardLogo from "../../assets/icons/mastercard-logo.svg";
import RupayBankLogo from "../../assets/icons/rupay-bank-logo.png"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

import { 
  GetVCNDetails 
} from '../../redux/actions/vcnActions';

import style from "../../global.scss";
import "./styles.scss";

const VirtualCardsInfoModal = ({open, setOpen, title, id}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.vcn);
  const [handleCurrency] = useCurrencyService();
  const [loading, setLoading] = useState(true);

  const [card, setCard] = useState(null);

  const [copiedCC, setCopiedCC] = useState(false);
  const [copiedCVV, setCopiedCVV] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  }

  useEffect(() => {
    if(open){
      dispatch(GetVCNDetails(id, setLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatVirtualCardsDetailsObj(state.vcnDetails.Records[0] || null);
    } catch (err) {}
  }, [state]);

  // ------------------ obj formatter ------------------
  const formatVirtualCardsDetailsObj = (obj) => {
    setCard(obj)
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px" }}
          className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }} mb={"30px"}>
              {title}
            </Typography>
            
            {
              loading
              ?
              <Box height={"400px"} className="flexCenterCenterRow">
                <CircularProgress />
              </Box>
              :
                card === null
                ?
                <Box height={"400px"} className="flexCenterCenterRow">
                  <Typography variant='subtitle1'>Something went wrong</Typography>
                </Box>
                :
                <>
                  <Box className={`flex_SBColumn vcn__card ${card.CurrentStatus !== "active" && 'vcn__card-cancelled'}`}
                    sx={{ padding: "15px", maxWidth: "360px" }} margin={"auto"}>
                    <Box className="flexCenterSBRow">
                      <Typography 
                        variant='subtitle1'
                        fontWeight={600}>
                        {handleCurrency(card.Currency, card.Amount) || "NA"}
                      </Typography>
                      <img
                        src={RupayBankLogo}
                        alt='rupay-bank-logo'
                        style={{ height: "20px", }}
                        />
                      {/* <Chip
                        label={card.CurrentStatus || "NA"} 
                        /> */}
                    </Box>
                    <Box className="flexCenter_Row">
                      <Typography variant='h6'>
                        {card.Card.Pan.slice(0, 4)}&nbsp;
                        {card.Card.Pan.slice(4, 8)}&nbsp;
                        {card.Card.Pan.slice(8, 12)}&nbsp;
                        {card.Card.Pan.slice(12, 16)}&nbsp;
                      </Typography>
                      <IconButton p={1}
                        onClick={() => {
                          setCopiedCC(true);
                          setCopiedCVV(false);
                          navigator.clipboard.writeText(card.Card.Pan);
                        }}>
                        {
                          copiedCC
                          ?
                          <ContentCopyTwoToneIcon />
                          :
                          <ContentCopyIcon />
                        }
                      </IconButton>
                    </Box>
                    <Box className="flexCenterSBRow">
                      <Box>
                        <Typography variant='subtitle1'>
                          <i>Expiry:</i>&nbsp;{card.Card.Expiry.slice(0, 2)}/{card.Card.Expiry.slice(2, 4)}
                        </Typography>
                      </Box>
                      <Box className="flexCenter_Row">
                        <Typography variant='subtitle1'>
                          <i>CVV:</i>&nbsp;{card.Card.Avv}&nbsp;
                        </Typography>
                        <IconButton p={1}
                          onClick={() => {
                            setCopiedCC(false);
                            setCopiedCVV(true);
                            navigator.clipboard.writeText(card.Card.Avv);
                          }}>
                          {
                            copiedCVV
                            ?
                            <ContentCopyTwoToneIcon />
                            :
                            <ContentCopyIcon />
                          }
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className="flexCenterSBRow">
                      <Typography variant='body1'>
                        {"RealCard" in card.ParentConfigurations
                        ? card.ParentConfigurations.RealCard.Alias 
                        : card.ParentConfigurations.Wallet.Alias || "NA"}
                      </Typography>
                      <img
                        src={MasterCardLogo}
                        alt="mastercard-logo"
                        style={{ width: "40px" }}
                        />
                    </Box>
                  </Box>
                  {
                    !loading
                    &&
                    <Box mt={"20px"} ml={"30px"}>
                      <Typography>
                        <i>Description</i>:&nbsp;
                        {card.Description || "NA"}
                      </Typography>
                    </Box>
                  }

                  {
                    !loading
                    &&
                    <Box mt={"10px"}>
                      <Timeline position="left" sx={{ width: "100%" }}>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant='body2'>
                              {card.Timeline.From
                                && new Date(card.Timeline.From).toLocaleString()}
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"  />
                            <TimelineConnector sx={{ height: "60px" }}/>
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant='body2'><i>Valid from</i></Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant='body2'>
                              {card.Timeline.ValidThru
                                && new Date(card.Timeline.ValidThru).toLocaleString()}
                              {
                                (Date.now() >= new Date(card.Timeline.ValidThru))
                                ? <Typography variant='body2' color="error"><i>(Expired)</i></Typography>
                                : <></>
                              }
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot variant="outlined" color="secondary" />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant='body2'><i>Valid till</i></Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Box>
                  }
                </>
            }
          </Box>

          <Divider
          sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default VirtualCardsInfoModal;