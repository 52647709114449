import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  Container, 
  TextField, 
  Typography 
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import { 
  GetProfile, 
  UpdateProfile 
} from '../../redux/actions/profileActions';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  FirstName: "",
  MiddleName: "",
  LastName: "",
  Email: ""
};

const SetupFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.profile);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  useEffect(() => {
    try {
      formatProfileObj(state.profile.Profile || null);
    } catch (err) {}
  }, [state]);

  // -------------------- obj formatter --------------------
  const formatProfileObj = (obj) => {
    setFormData({
      ...Data,
      FirstName: obj.FirstName || "",
      MiddleName: obj.MiddleName || "",
      LastName: obj.LastName || "",
      Email: obj.ContactInfo.Email || ""
    })
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }


  const handleSubmitProfile = () => {
    dispatch(UpdateProfile(formData, setLoading)).then(() => {
      dispatch(GetProfile(setLoading)).then(() => {
        navigate("/");
      })
    });
  }

  return (
    <Box height={"100vh"}>
      <Container 
        sx={{
          width: "100%",
          maxWidth: "400px",
          height: "100vh"
        }}>
        <Box
          className="flex_CenterColumn"
          height={"100%"}
          gap={"20px"}>
          <Typography variant='h6' textAlign={"center"}
            sx={{ color: style["secondary"] }}>
            Complete your profile
          </Typography>
          <TextField
            variant='outlined'
            name='FirstName'
            label='First Name'
            autoFocus={true}
            fullWidth={true}
            inputProps={{ maxLength: 100 }}
            value={formData.FirstName}
            onChange={handleChange}
            />
          <TextField
            variant='outlined'
            name='MiddleName'
            label='Middle Name (optional)'
            fullWidth={true}
            inputProps={{ maxLength: 100 }}
            value={formData.MiddleName}
            onChange={handleChange}
            />
          <TextField
            variant='outlined'
            name='LastName'
            label='Last Name'
            fullWidth={true}
            inputProps={{ maxLength: 100 }}
            value={formData.LastName}
            onChange={handleChange}
            />
          <TextField
            variant='outlined'
            name='Email'
            label='Email'
            fullWidth={true}
            inputProps={{ maxLength: 64 }}
            value={formData.Email}
            onChange={handleChange}
            />
          <LoadingButton
            variant='contained'
            disabled={
              !(formData.FirstName !== "" && formData.LastName !== "")
            }
            loading={loading}
            onClick={handleSubmitProfile}>
            Submit
          </LoadingButton>
        </Box>    
      </Container>
    </Box>
  )
}

export default SetupFlow;