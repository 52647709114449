import * as actionTypes from "../actionTypes.js";

const initialState = {
  myWalletsList: null,
  myWalletDetails: null, 
  associateWalletsList: null, 
  associateWalletDetails: null,
  walletsVCNsList: null
};

const associationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WALLETS:
      return { ...state, myWalletsList: action.payload };
    case actionTypes.GET_WALLET_DETAILS:
      return { ...state, myWalletDetails: action.payload };
    case actionTypes.GET_ASSOCIATE_WALLETS:
      return { ...state, associateWalletsList: action.payload };
    case actionTypes.GET_ASSOCIATE_WALLET_DETAILS:
      return { ...state, associateWalletDetails: action.payload };

    case actionTypes.GET_WALLET_VCNS:
      return { ...state, walletsVCNsList: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;
   
    default:
      return state;
  }
};

export default associationReducer;