import * as actionTypes from "../actionTypes.js";

const initialState = {
  searchUsersList: null,
  pendingRequestsList: null,
  circleDetails: null
};

const circleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_USERS:
      return { ...state, searchUsersList: action.payload };
    case actionTypes.GET_PENDING_REQUESTS:
      return { ...state, pendingRequestsList: action.payload };
    case actionTypes.GET_CIRCLE_DETAILS:
      return { ...state, circleDetails: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default circleReducer;