import * as actionTypes from "../actionTypes.js";

const initialState = {
  isAuthenticated: false,
  user: null,
  profileStatus: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOG_IN:
      localStorage.setItem("user", JSON.stringify(action.payload.tokens));
      return { ...state, user: action.payload.tokens, isAuthenticated: true, profileStatus: action.payload.profileStatus };
    case actionTypes.REGISTER:
      localStorage.setItem("user", JSON.stringify(action.payload.tokens));
      return { ...state, user: action.payload.tokens, isAuthenticated: true, profileStatus: action.payload.profileStatus };
      
    case actionTypes.LOG_OUT:
      localStorage.removeItem("user");
      return initialState;

    default:
      return state;
  }
};

export default authReducer;