import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
  Box,
  IconButton, 
  Typography
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AddCardTwoToneIcon from '@mui/icons-material/AddCardTwoTone';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import "./styles.scss";

const SideNavigation = ({
  currentPage,
  isNavExpanded,
  setIsNavExpanded,
}) => {
  const navigate = useNavigate();
  const [showNavExpandBtn, setShowNavExpandBtn] = useState(false);

  return (
    <Box  
      className={`nav__side ${isNavExpanded ? 'nav__side-expanded' : 'nav__side-collapsed'}`}
      onMouseEnter={() => {
        setShowNavExpandBtn(true);
      }}
      onMouseLeave={() => {
        setShowNavExpandBtn(false);
      }}
      display={"flex"} 
      flexDirection={"column"} 
      gap={"10px"}
      justifyContent={"space-between"}>
      {
        <Box className="nav__side-conatiner">
          <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => navigate("/")}>
            <AddCardTwoToneIcon />
            {
              isNavExpanded && 
              <Typography variant='body2'>
                Virtual Cards
              </Typography>
            }
          </Box>
          {/* <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => {
              navigate("/");
            }}>
            <HomeOutlinedIcon />
            {
              isNavExpanded && 
              <Typography variant='body2'>
                Home
              </Typography>
            }
          </Box> */}
          <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => navigate("/cards")}>
            <CreditCardIcon />
            {
              isNavExpanded && 
              <Typography variant='body2'>
                Real Cards
              </Typography>
            }
          </Box>
          <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => navigate("/circles")}>
            <GroupsOutlinedIcon />
            {
              isNavExpanded && 
              <Typography variant='body2'>
                Circles
              </Typography>
            }
          </Box>
          <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => navigate("/buckets")}>
            <Inventory2OutlinedIcon />
            {
              isNavExpanded && 
              <Typography variant='body2'>
                Wallets
              </Typography>
            }
          </Box>
        </Box>
      }
      <Box className="nav__side-expand">
      {
        showNavExpandBtn
        &&
        <IconButton
          sx={{ backgroundColor: "#f1f1f1"}}
          onClick={() => {
            localStorage.setItem("navExpanded", !isNavExpanded);
            setIsNavExpanded(!isNavExpanded);
            setShowNavExpandBtn(false);
          }}>
          {
            isNavExpanded
            ?
            <ArrowBackIosNewIcon />
            :
            <ArrowForwardIosIcon />
          }
        </IconButton>
      }
      </Box>
    </Box>
  )
}

export default SideNavigation;