import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip,
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../components/utils/TablePaginationActions';
import TableLoading from '../../components/loaders/TableLoading';
import TopBar from '../../components/topBar/TopBar';

import { 
  GetCards, 
  ToggleCard 
} from '../../redux/actions/cardActions';

import CardsModal from './CardsModal';

import "./styles.scss";

const CardsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.card);
  const [loading, setLoading] = useState(true);
  const [toggling, setToggling] = useState(false);
  const [cardsModalOpen, setCardsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState({});

  const [cardsList, setCardsList] = useState([]);
  const [cardsTotal, setCardsTotal] = useState(0);

  // --------------------- table pagination ----------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    dispatch(GetCards(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      formatCardsList(state.cardsList.Records || []);
      setCardsTotal(state.cardsList.Total || 0);
    } catch (err) {}
  }, [state]);  

  // ------------------------- list formatter -------------------------
  const formatCardsList = (list) => {
    setCardsList(list);
  }

  const handleToggleCard = (id) => {
    dispatch(ToggleCard(id, setToggling)).then(() => {
      dispatch(GetCards(page+1, rowsPerPage, setToggling))
    })
  }

  return (
    <Box width={"100%"}>

      <CardsModal 
        open={cardsModalOpen}
        setOpen={setCardsModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Real Cards"}
        button1Text={"Add Card"}
        button1OnClick={() => {
          setModalTitle("Add Real Card");
          setCardsModalOpen(!cardsModalOpen);
        }}
        />

      {
        loading
        ?
        <TableLoading />
        :
          cardsList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No real card found</Typography>
          </Box>
          :
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Alias</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Last 4 digits</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                cardsList.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ backgroundColor: row.Status === false && "#faf1f1"}}>
                    <TableCell>{row.Alias || "NA"}</TableCell>
                    <TableCell>{row.Currency || "NA"}</TableCell>
                    <TableCell>{row.Last4Digits || "NA"}</TableCell>
                    <TableCell>{row.Network || "NA"}</TableCell>
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Chip
                        label={row.Status ? "active" : "inactive"} 
                        />
                    </TableCell>
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Switch 
                        checked={row.Status}
                        onChange={(e) => {
                          handleToggleCard(row.Id);
                        }}
                        />
                      {/* <IconButton
                        onClick={() => {
                          setUpdate(row);
                          setModalTitle("Edit Credit Card");
                          setCardsModalOpen(!cardsModalOpen);
                        }}>
                        <EditTwoToneIcon sx={{ color: style['secondary_light']}}/>
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]} 
                  colSpan={10}
                  count={cardsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default CardsPage;