import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return true;
    } else {
        return false;
    }
};

const ProtectedRoutes = (props) => {
    const state = useSelector(state => state.auth);
    const auth = useAuth();
    const profile = useSelector(state => state.profile);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            if(location.pathname !== "/setup"){
                if((profile.profile.Profile.FirstName === "" || 
                    profile.profile.Profile.FirstName === null) && 
                    state.isAuthenticated === true){
                    navigate("/setup");
                }
            }
        } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return auth ? (
        <>
            <Outlet />
        </>
    ) : (
        <Navigate to="/auth" />
    );
};

export default ProtectedRoutes;