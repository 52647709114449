import React from 'react';

import { 
  Box, 
  Container 
} from '@mui/material';

import Logo from "../../assets/icons/logo-blue.png";

import "./styles.scss";

const AppTopBar = () => {
  return (
    <Box className='app__topbar'>
      <Container maxWidth="lg">
        <Box className='flexCenterSBRow'>
          <img
            src={Logo}
            alt="lusid-logo"
            style={{ width: "140px" }}
            />
        </Box>
      </Container>
    </Box>
  )
}

export default AppTopBar;