import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Modal, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography
} from '@mui/material';

import TablePaginationActions from '../../components/utils/TablePaginationActions';
import useCurrencyService from '../../components/utils/CurrencyService';
import BucketTypeChip from '../../components/utils/BucketTypeChip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import { 
  DeleteWallet,
  GetAssociateWallets,
} from '../../redux/actions/associationActions';

import ConfirmationModal from '../../components/modals/ConfirmationModal';
import BucketInfoModal from './BucketInfoModal';

import style from "../../global.scss";
import "./styles.scss";

const BucketsModal = ({open, setOpen, title, id}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.associate);
  const [handleCurrency] = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [bucketInfoModalOpen, setBucketInfoModalOpen] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState("");

  const [associateWalletsList, setAssociateWalletsList] = useState(null);
  const [associateWalletsTotal, setAssociateWalletsTotal] = useState(0);

  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // --------------------- table pagination ----------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  }

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  }

  useEffect(() => { 
    if(open) {
      dispatch(GetAssociateWallets(id, page+1, rowsPerPage, setLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatAssociateWalletsList(state.associateWalletsList.Records || []);
      setAssociateWalletsTotal(state.associateWalletsList.Total || 0);
    } catch (err) {}
  }, [state]);

  // -------------------- list formatter --------------------
  const formatAssociateWalletsList = (list) => {
    setAssociateWalletsList(list);
  }

  // ------------------------- delete wallet --------------------------
  const handleDeleteWallet = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteWallet(id, deleteId, setDeleting)).then(() => {
      dispatch(GetAssociateWallets(id, page+1, rowsPerPage, setDeleting));
    })
  }

  return (
    <>
  
      <BucketInfoModal 
        open={bucketInfoModalOpen}
        setOpen={setBucketInfoModalOpen}
        title={modalTitle}
        userId={update[0]}
        walletId={update[1]}
        />
         
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDeleteWallet}
        />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="flexCenterCenterRow"
          width={"100vw"} height={"100vh"}>
          <Box sx={{ maxWidth: "lg", margin: "10px" }}
          className="confirm flex_SBColumn">
            <Box width={"100%"}
              sx={{ padding: "15px 15px 30px 15px"}}>
              <Typography variant='h6' sx={{ color: style["secondary"] }}>
                {title}
              </Typography>
              <Typography variant='body1' mb={'20px'}>
              
              </Typography>

              {
                loading
                ?
                <Box height={"200px"} className="flexCenterCenterRow">
                  <CircularProgress />
                </Box>
                :
                  associateWalletsList.length === 0
                  ?
                  <Box height={"200px"} className="flexCenterCenterRow">
                    <Typography>No wallet found</Typography>
                  </Box>
                  :
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Alias</TableCell>
                        <TableCell>Available</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Expiry</TableCell>
                        <TableCell>Card</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Card count</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        associateWalletsList.map((row, index) => (
                          <TableRow 
                            key={index}
                            sx={{ backgroundColor: row.Status !== "active" && "#faf1f1" }}>
                            <TableCell>{row.Alias}&nbsp;
                              <BucketTypeChip>{row.Type}</BucketTypeChip>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight={600}>
                                {handleCurrency(row.Currency, row.AvailableLimit) || "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight={600}>
                                {handleCurrency(row.Currency, row.Amount) || "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.ExpiresOn ? new Date(row.ExpiresOn).toDateString().slice(4) : "NA"}</TableCell>
                            <TableCell>{row.RealCard.Alias}</TableCell>
                            <TableCell sx={{ pt: 1, pb: 1 }}>
                              <Chip
                                label={row.Status === "active" ? row.Status : "inactive" || "NA"} 
                                />
                            </TableCell>
                            <TableCell>{row.VirtualCardsCount}</TableCell>
                            <TableCell sx={{ pt: 1, pb: 1, width: "120px" }}>
                              {
                                row.Status === "active"
                                ?
                                <Box className="flexCenter_Row" gap={"20px"}>
                                  <IconButton
                                    onClick={() => {
                                      setUpdate([id, row.Id]);
                                      setModalTitle("Wallet details for " + row.Alias);
                                      setBucketInfoModalOpen(!bucketInfoModalOpen);
                                    }}>
                                    <InfoOutlinedIcon sx={{ color: style['secondary_light']}}/>
                                  </IconButton>
                                  {
                                    deleteId === row.Id && deleting
                                    ?
                                    <CircularProgress size={"24px"}/>
                                    :
                                    <Tooltip title={"Delete " + row.Alias}>
                                      <IconButton
                                        onClick={() => {
                                          setDeleteId(row.Id);
                                          setModalTitle("Delete " + row.Alias);
                                          setDeleteConfirmationModalOpen(!deleteConfirmationModalOpen);
                                        }}>
                                        <DeleteTwoToneIcon sx={{ color: style['secondary_light']}}/>
                                      </IconButton>
                                    </Tooltip>
                                  }
                                </Box>
                                :
                                <></>
                              }
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          sx={{ borderBottom: 0 }}
                          rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]} 
                          colSpan={10}
                          count={associateWalletsTotal}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page'
                            },
                            native: true
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                    </TableFooter>
                  </Table>
              }
            </Box>
            <Divider
            sx={{ backgroundColor: style["primary_light"] }}/>
            <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
              <Button variant='outlined'
                onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default BucketsModal;