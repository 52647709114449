import React from "react";

import { 
  Box 
} from "@mui/material";

import Logo from "../../assets/icons/logo-blue.png";
import MiniLogo from "../../assets/icons/mini-logo-blue.png"; 

const SideBarLogo = ({isNavExpanded}) => {
  return(
    <Box height={"60px"}
      sx={{
        position: "absolute", 
        zIndex: 100, top: 0, left: 0, 
        padding: "10px" }}>
      {
        isNavExpanded
        ?
        <img
          src={Logo}
          alt="logo"
          style={{ width: "140px" }}
          /> 
        :
        <Box 
          className={"mini-logo flexCenterCenterRow"}>
          <img
            src={MiniLogo}
            alt="mini-logo"
            style={{ width: "47px" }}
            /> 
        </Box>
      }
    </Box>
  )
}

export default React.memo(SideBarLogo);