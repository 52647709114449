import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Divider,
  Modal, 
  Skeleton,  
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import {
  AddUserToCricle,
  GetCircleDetails,
  GetUserAddRequests 
} from '../../redux/actions/circleActions';

import EnterCodeModal from './EnterCodeModal';

import style from "../../global.scss";
import "./styles.scss";

const PendingRequestsModal = ({open, setOpen, title}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.circle);
  const [loading, setLoading] = useState(false);
  const [enterCodeModalOpen, setEnterCodeModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState({});

  const [requestsList, setRequestsList] = useState([]);

  const [code, setCode] = useState("");

  const [requesting, setRequesting] = useState(false);
  const [requestId, setRequestId] = useState("");

  useEffect(() => {
    if(open){
      dispatch(GetUserAddRequests(setLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setRequestsList([]);
    setCode("");
  }

  useEffect(() => {
    try {
      formatRequestsList(state.pendingRequestsList.Records || []);
    } catch (err) {}
  }, [state]);

  // --------------------- list formatter ---------------------
  const formatRequestsList = (list) => {
    setRequestsList(list);
  }

  const handleAddUserToCircle = () => {
    setEnterCodeModalOpen(false);
    setRequestId(update.Id);

    let obj = {
      RequestId: update.Id,
      ReceiverId: update.Receiver.Id,
      CircleCode: code
    }

    dispatch(AddUserToCricle(obj, setRequesting)).then(() => {
      dispatch(GetUserAddRequests(setRequesting)).then(() => {
        dispatch(GetCircleDetails(setRequesting));
      })
    })
  }


  return (
    <>
    <EnterCodeModal
      open={enterCodeModalOpen}
      setOpen={setEnterCodeModalOpen}
      title={modalTitle}
      code={code}
      setCode={setCode}
      handleSubmit={handleAddUserToCircle}
      />
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px", height: "80vh" }}
          className="modal flex_SBColumn">
          <Box>
            <Box width={"100%"}
              sx={{ margin: "15px 15px 30px 15px"}}>
              <Typography variant='h6' sx={{ color: style["secondary"] }}>
                {title}
              </Typography>
            </Box>

            {
              loading
              ?
              <Box p={"15px"}>
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
              </Box>
              : 
                requestsList.length === 0
                ?
                <Box className="flexCenterCenterRow" height={"200px"}>
                  <Typography>No request found</Typography>
                </Box>
                :
                <Box p={"15px"}
                  display={"flex"} flexDirection={"column"}
                  gap={"15px"}>
                  {
                    requestsList.map((item, index) => (
                      <Box key={index} className="user flexCenterSBRow">
                        <Box>
                          <Typography>
                            {item.Receiver.Name}
                          </Typography>
                          <Typography color={"grey"}>
                            {item.Receiver.Mobile}
                          </Typography>
                        </Box>
                        <LoadingButton
                          variant="text"
                          loading={
                            requestId === item.Id && requesting ? true : false
                          }
                          onClick={() => {
                            setCode("");
                            setModalTitle("Enter circle code of " + item.Receiver.Name);
                            setUpdate(item);
                            setEnterCodeModalOpen(!enterCodeModalOpen);
                          }}>
                          Enter Code
                        </LoadingButton>
                      </Box>
                    ))
                  }
                </Box>
            }
          </Box>
          <Box>
            <Divider
              sx={{ backgroundColor: style["primary_light"] }}/>
            <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
              <Button variant='outlined'
                onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
    </>
  )
}

export default PendingRequestsModal;