import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const GetCards = (page, limit, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getCards(page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_CARDS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const AddCard = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.addCard(formData);
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const ToggleCard = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.toggleCard(id);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};