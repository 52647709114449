import * as actionTypes from "../actionTypes.js";

const initialState = {
  cardsList: null,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CARDS:
      return { ...state, cardsList: action.payload };
   
    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default cardReducer;