import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  Chip, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,  
  Tooltip,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../components/utils/TablePaginationActions';
import useCurrencyService from '../../components/utils/CurrencyService';
import BucketTypeChip from '../../components/utils/BucketTypeChip';
import TableLoading from '../../components/loaders/TableLoading';
import TopBar from '../../components/topBar/TopBar';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { 
  GetWallets 
} from '../../redux/actions/associationActions';

import WalletInfoModal from './BucketInfoModal';

import { SELECT_BUCKET } from '../../redux/actionTypes';

import style from "../../global.scss";
import "./styles.scss";

const BucketsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state.associate);
  const profile = useSelector(state => state.profile);
  const [handleCurrency] = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [walletInfoModalOpen, setWalletInfoModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState("");
  
  const [myWalletsList, setMyWalletsList] = useState([]);
  const [myWalletsTotal, setMyWalletsTotal] = useState(0);

  // --------------------- table pagination ----------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    dispatch(GetWallets(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      // my wallets
      formatMyWalletsList(state.myWalletsList.Records || []);
      setMyWalletsTotal(state.myWalletsList.Total || 0);
    } catch (err) {}
  }, [state]);  

  // ------------------------- list formatter -------------------------
  const formatMyWalletsList = (list) => {
    setMyWalletsList(list);
  }

  return (
    <Box width={"100%"}>

      <TopBar 
        title={"My Wallets"}
        />

      <WalletInfoModal 
        open={walletInfoModalOpen}
        setOpen={setWalletInfoModalOpen}
        title={modalTitle}
        id={update}
        />

      {
        loading
        ?
        <TableLoading />
        :
          myWalletsList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No bucket found</Typography>
          </Box>
          :
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Wallet name</TableCell>
                <TableCell>Available</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Expiry</TableCell>
                <TableCell>From</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Card count</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                myWalletsList.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ backgroundColor: row.Status !== "active" && "#faf1f1" }}>
                    <TableCell>{row.Alias}&nbsp;
                      <BucketTypeChip>{row.Type}</BucketTypeChip>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>
                        {handleCurrency(row.Currency, row.AvailableLimit) || "NA"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>
                        {handleCurrency(row.Currency, row.Amount) || "NA"}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.ExpiresOn ? new Date(row.ExpiresOn).toDateString().slice(4) : "NA"}</TableCell>
                    <TableCell>{row.From}</TableCell>
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Chip
                        label={row.Status === "active" ? row.Status : "inactive" || "NA"} 
                        />
                    </TableCell>
                    <TableCell>
                      {row.VirtualCardsCount}
                      {
                        row.VirtualCardsCount > 0
                        &&
                        <Tooltip title={"See virtual cards of "+ row.Alias + " wallet"}>
                          <IconButton
                            onClick={() => {
                              dispatch({ type: SELECT_BUCKET, payload: row.Alias });
                              navigate(`/buckets/${profile.profile.Profile.Id}/${row.Id}`);
                            }}>
                            <CreditCardIcon sx={{ color: style['secondary_light']}}/>
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Box className="flexCenter_Row" gap={"20px"}>
                        <IconButton
                          onClick={() => {
                            setUpdate(row.Id);
                            setModalTitle("Wallet details for " + row.Alias);
                            setWalletInfoModalOpen(!walletInfoModalOpen);
                          }}>
                          <InfoOutlinedIcon sx={{ color: style['secondary_light']}}/>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]} 
                  colSpan={10}
                  count={myWalletsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }    
    </Box>
  )
}

export default BucketsPage;