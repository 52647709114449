import React from 'react';

import { 
  Box, 
  Button, 
  Typography 
} from '@mui/material';

import "./styles.scss";

const TopBar = (props) => {
  return (
    <Box className="topbar flexCenterSBRow">
      <Typography variant='h5'>
        {props.title}
      </Typography>

      <Box className="flexCenter_Row" gap={"20px"}>
        {
          (props.button2Text && props.button2OnClick)
          &&
          <Button 
            variant='contained'
            onClick={props.button2OnClick}>
            {props.button2Text}
          </Button>
        }
        {
          (props.button1Text && props.button1OnClick)
          &&
          <Button
            variant='contained'
            onClick={props.button1OnClick}>
            {props.button1Text}
          </Button>
        }
      </Box>
    </Box>
  )
}

export default TopBar;