import React from 'react';
import Countdown from 'react-countdown';
import OTPInput from 'react-otp-input';

import {
  Box,
  Button,
  Divider,
  Link,
  Modal, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import style from "../../global.scss";
import "./styles.scss";

const OtpModal = ({
  open, 
  setOpen, 
  title, 
  otp, 
  setOtp, 
  allowResendCode,
  setAllowResendCode,
  handleSubmit, 
  handleResend,
  startTime
}) => {

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px" }}
          className="modal">
          <Box width={"100%"}
            sx={{ margin: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }}>
              {title}
            </Typography>
            <Typography variant='body1'>
              Enter one time password received on your mobile number.
            </Typography>
          </Box>

          <Box
            gap={"15px"}
            sx={{ width: "100%" }}>
            <OTPInput
              value={otp}
              onChange={(e) => {setOtp(e)}}
              shouldAutoFocus={true}
              numInputs={4}
              isInputSecure={true}
              containerStyle={"vcn__otp"}
              inputStyle={"vcn__otp-input"}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />
              }
            />
            <Box
              padding={"20px"}>
            {
              allowResendCode
              ?
              <Link
                pt={"5px"}
                onClick={() => handleResend()}>
                Resend code
              </Link>
              :
              <Typography
                fontSize={"17px"}
                fontWeight={"600"}>
                Resend code in&nbsp;
                <Countdown
                  date={startTime}
                  renderer={({ hours, minutes, seconds, completed }) => {
                      return <span>{seconds}</span>;
                    }
                  }
                  onComplete={() => {
                    setAllowResendCode(true);
                  }} />
                &nbsp;seconds
              </Typography>
            }
            </Box>
          </Box>
      
          <Divider
          sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default OtpModal;