import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import { 
  GetProfile, 
  UpdateProfile 
} from '../../redux/actions/profileActions';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  FirstName: "",
  MiddleName: "",
  LastName: "",
  Email: ""
}

const ProfileModal = ({open, setOpen, title, update}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
  }

  useEffect(() => {
    if(open){
      setFormData({
        ...Data, 
        FirstName: update.FirstName || "",
        MiddleName: update.MiddleName || "",
        LastName: update.LastName || "",
        Email: update.Email || ""
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleUpdateProfile = () => {
    dispatch(UpdateProfile(formData, setLoading)).then(() => {
      dispatch(GetProfile(setLoading)).then(() => {
        handleClose();
      })
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px" }}
          className="modal">
          <Box width={"100%"}
            sx={{ margin: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }}>
              {title}
            </Typography>
          </Box>
          <Box margin={"20px 15px"} className="flexCenter_Row" gap={"20px"}>
            <TextField
              variant='outlined'
              name='FirstName'
              label='First Name'
              autoFocus={true}
              fullWidth={true}
              inputProps={{ maxLength: 100 }}
              value={formData.FirstName}
              onChange={handleChange}
              />
            <TextField
              variant='outlined'
              name='MiddleName'
              label='Middle Name (optional)'
              fullWidth={true}
              inputProps={{ maxLength: 100 }}
              value={formData.MiddleName}
              onChange={handleChange}
              />
          </Box>
          <Box margin={"20px 15px"} className="flexCenter_Row" gap={"20px"}>
            <TextField
              variant='outlined'
              name='LastName'
              label='Last Name'
              fullWidth={true}
              inputProps={{ maxLength: 100 }}
              value={formData.LastName}
              onChange={handleChange}
              />
            <TextField
              variant='outlined'
              name='Email'
              label='Email'
              fullWidth={true}
              inputProps={{ maxLength: 64 }}
              value={formData.Email}
              onChange={handleChange}
              />
          </Box>
          <Divider
          sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              disabled={
                !(formData.FirstName !== "" && formData.LastName !== "")
              }
              loading={loading}
              onClick={handleUpdateProfile}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ProfileModal;