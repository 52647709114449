import React from 'react';
import OTPInput from 'react-otp-input';

import {
  Box,
  Button,
  Divider,
  Modal,  
  Typography
} from '@mui/material';

import style from "../../global.scss";
import "./styles.scss";

const EnterCodeModal = ({open, setOpen, title, code, setCode, handleSubmit}) => {
  
  const handleClose = () => {
    setOpen(false);
    setCode("");
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px" }}
          className="modal flex_SBColumn">
          <Box width={"100%"}
            sx={{ margin: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }}>
              {title}
            </Typography>
            <Typography variant='body1'>
              Ask for circle code from your contact.
            </Typography>
            <Box
              className="flexCenterSBRow"
              gap={"15px"}
              sx={{ width: "calc(100% - 30px)", padding: "20px 10px" }}>
              <OTPInput
                value={code}
                onChange={(e) => {setCode(e)}}
                shouldAutoFocus={true}
                numInputs={6}
                isInputSecure={true}
                containerStyle={"circle__otp"}
                inputStyle={"circle__otp-input"}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />
                }
              />
            </Box>
          </Box>
          <Divider
            sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained'
              disabled={code.length < 6 ? true : false}
              onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default EnterCodeModal