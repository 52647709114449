import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Avatar,
  Box,
  IconButton, 
  Menu, 
  MenuItem, 
  Typography
} from '@mui/material';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import ConfirmationModal from '../modals/ConfirmationModal';

import * as actionTypes from "../../redux/actionTypes";

import global from "../../global.scss";
import "./styles.scss";

const TopNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state.profile);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [profileAnchor, setProfileAnchor] = useState(null);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const [profile, setProfile] = useState(null);

  const handleCloseProfileMenu = () => {
    setProfileAnchor(null);
    setProfileMenuOpen(false);
  };

  useEffect(() => {
    try {
      formatProfileObj(state.profile.Profile || null);
    } catch (err) {}
  }, [state]);

  const formatProfileObj = (obj) => {
    setProfile(obj);
  }

  // ======================= Log out handler ============================
  const handleLogout = () => {
    dispatch({ type: actionTypes.LOG_OUT });
    navigate("/auth")
  }

  return (
    <Box className="nav__topbar flexCenterSBRow">
      <ConfirmationModal 
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={"Log out"}
        onConfirm={handleLogout}
        />

      <Box></Box>

      <IconButton
        onClick={(event) => {
          setProfileAnchor(event.currentTarget);
          setProfileMenuOpen(!profileMenuOpen);
        }}>
        <Avatar
          sx={{ backgroundColor: global["secondary"]}}>
          <Typography
            color={"white"}>
            {profile && profile.FirstName ? profile.FirstName[0]?.toUpperCase() : ""}
            {profile && profile.LastName ? profile.LastName[0]?.toUpperCase() : ""}
          </Typography>
        </Avatar>
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={profileAnchor}
        open={profileMenuOpen}
        onClose={handleCloseProfileMenu}
      >
        <MenuItem
          className='flexCenter_Row'
          sx={{ gap: "15px" }}
          onClick={() => {
            handleCloseProfileMenu();
            navigate("/profile");
          }}>
          <AccountCircleOutlinedIcon />  
          <Typography sx={{ marginRight: "20px" }}>Profile</Typography>
        </MenuItem>
        <MenuItem
          className='flexCenter_Row'
          sx={{ gap: "15px" }}
          onClick={() => {
            setConfirmationModalOpen(true);
            handleCloseProfileMenu();
          }}>
          <LogoutIcon />  
          <Typography sx={{ marginRight: "20px" }}>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default TopNavigation