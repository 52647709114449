import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

import { GetCurrencies } from "./miscellaneousActions";

// ============================== REGISTER =============================
export const SendRegistrationCode = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.sendRegistrationCode(formData);
    res = data;
    statusCode = status;
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return {res, statusCode};
};

export const ResendRegistrationCode = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.resendRegistrationCode(formData);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const Register = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var res, statusCode;
  try {
    const { data, status } = await api.register(formData);
    res = data;
    statusCode = status;
    if (status === 200) {
      dispatch({ type: actionTypes.REGISTER, payload: data.data });
      toast.success(data.message);
      dispatch(GetCurrencies(1, 500));
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return {res, statusCode};
};

// ============================== LOGIN ================================
export const SendLoginCode = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.sendLoginCode(formData);
    res = data;
    statusCode = status;
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
    return {res, statusCode};
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
    new Error("");
  }
};

export const ResendLoginCode = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.resendLoginCode(formData);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const Login = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.login(formData);
    res = data;
    statusCode = status;
    if (status === 200) {
      dispatch({ type: actionTypes.LOG_IN, payload: data.data });
      toast.success(data.message);
      dispatch(GetCurrencies(1, 500));
      navigate("/");
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return {res, statusCode};
};

// ============================== REFRESH_ACCESS ================================
export const RefreshAccess = (setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.refreshAccess();
    if (status === 200) {
      
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};