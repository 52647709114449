import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducer from "./authReducers";
import ProfileReducer from "./profileReducers";
import CardReducer from "./cardReducers";
import VcnReducer from "./vcnReducers";
import CircleReducer from "./circleReducers";
import AssociationReducer from "./associationReducers";
import MiscellaneousReducer from "./miscellaneousReducers";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  profile: ProfileReducer,
  card: CardReducer,
  vcn: VcnReducer,
  circle: CircleReducer,
  associate: AssociationReducer,
  other: MiscellaneousReducer
  
});

export default persistReducer(persistConfig, rootReducer);