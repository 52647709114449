import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Avatar,
  Box, 
  Button, 
  Container, 
  IconButton, 
  Skeleton, 
  Tooltip, 
  Typography
} from '@mui/material';

import AppTopBar from '../../components/topBar/AppTopBar';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';

import { 
  GetProfile 
} from '../../redux/actions/profileActions';

import ProfileModal from './ProfileModal';

import * as actionTypes from "../../redux/actionTypes";

import style from "../../global.scss";
import "./styles.scss";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.profile);
  const [loading, setLoading] = useState(true);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState(null);

  const [profile, setProfile] = useState(null);

  const [circleCodeCopied, setCircleCodeCopied] = useState(false);

  useEffect(() => {
    dispatch(GetProfile(setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatProfileObj(state.profile.Profile || null);
    } catch (err) {}
  }, [state]);

  // --------------------- obj formatter ---------------------
  const formatProfileObj = (obj) => {
    setProfile(obj);
  }

  // ======================= Log out handler ============================
  const handleLogout = () => {
    dispatch({ type: actionTypes.LOG_OUT });
    navigate("/auth");
  }

  return (
    <Box pt={"72px"}>
      <AppTopBar />
    
      <ProfileModal
        open={profileModalOpen}
        setOpen={setProfileModalOpen}
        title={"Update your profile"} 
        update={updateObj}
        />

      <Container 
        maxWidth="sm">
        <Box width={"100%"}
          className="flexCenter_Row"
          sx={{ margin: "15px 15px 30px 0px", gap: "15px" }}>
          <IconButton
            onClick={() => {navigate("/")}}>
            <ArrowBackTwoToneIcon
              sx={{ color: "grey", fontSize: "40px" }}/>
          </IconButton>
          <Typography variant='h6' sx={{ color: style["secondary"] }}>
            Profile
          </Typography>
        </Box>
        {
          loading
          ?
          <>
            <Box mb={"20px"} gap={"30px"}
              className="flexCenter_Row">
              <Skeleton
                variant="circular" 
                animation="wave"
                width={"100px"} height={"100px"} 
                mb={"20px"}
                sx={{ borderRadius: "50%", bgcolor: 'grey.100', marginBottom: "20px" }} />
              <Box>
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"220px"} height={"38px"} 
                  mb={"20px"}
                  sx={{ borderRadius: "2px", bgcolor: 'grey.100', marginBottom: "20px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"220px"} height={"38px"} 
                  mb={"20px"}
                  sx={{ borderRadius: "2px", bgcolor: 'grey.100', marginBottom: "20px" }} />
              </Box>
            </Box>
         
            <Skeleton
              variant="rectangular" 
              animation="wave"
              width={"100%"} height={"102px"} 
              mb={"20px"}
              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "20px" }} />
            <Skeleton
              variant="rectangular" 
              animation="wave"
              width={"100%"} height={"50px"} 
              mb={"20px"}
              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "20px" }} />
            <Skeleton
              variant="rectangular" 
              animation="wave"
              width={"100%"} height={"50px"} 
              mb={"20px"}
              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "20px" }} />
            <Skeleton
              variant="rectangular" 
              animation="wave"
              width={"100%"} height={"50px"} 
              mb={"20px"}
              sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "20px" }} />
          </>
          :
            profile 
            &&
            <Box>
              <Box className="flexCenter_Row" gap="30px" mb={"20px"}>
                <Avatar
                  sx={{ width: "100px", height: "100px"}}>
                  <Typography variant='h5'>
                    {profile.FirstName ? profile.FirstName[0]?.toUpperCase() : "L"}
                  </Typography>
                </Avatar>
                <Box>
                  <Typography variant='h5'>
                    {profile.FirstName || " "}&nbsp;
                    {profile.MiddleName || ""}{profile.MiddleName && " "}
                    {profile.LastName || " "}
                  </Typography>
                  <Typography variant='subtitle1'>
                    +{profile.ContactInfo.Mobile.CountryCode || "NA"}&nbsp;{profile.ContactInfo.Mobile.Number || "NA"}
                  </Typography>
                  {
                    profile.ContactInfo.Email
                    &&
                    <Typography variant='subtitle1'>
                      {profile.ContactInfo.Email || "NA"}
                    </Typography>
                  }
                </Box>
              </Box>

              <Box className="flexCenterCenterRow profile__circle" mb={"20px"}>
                <Typography mr={"15px"}>
                  Circle Code
                </Typography>
                <Typography variant='subtitle1'>
                  {profile.CircleCode || "NA"}
                </Typography>
                <Tooltip title="Copy circle code">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(profile.CircleCode || "");
                      setCircleCodeCopied(true);
                    }}>
                    {
                      circleCodeCopied
                      ?
                      <ContentCopyTwoToneIcon 
                        sx={{ color: style["secondary_light"] }}/>
                      : 
                      <ContentCopyIcon 
                        sx={{ color: style["secondary_light"] }}/>
                    }
                  </IconButton>
                </Tooltip>
              </Box>

              <Box className="flex_CenterColumn" gap={"20px"}>

                <Button
                  variant='outlined'
                  onClick={() => {
                    setUpdateObj({
                      FirstName: profile.FirstName,
                      MiddleName: profile.MiddleName,
                      LastName: profile.LastName,
                      Email: profile.ContactInfo.Email
                    });
                    setProfileModalOpen(!profileModalOpen);
                  }}>
                  <EditIcon sx={{ mr: "15px" }}/>
                  Edit Profile
                </Button>

                <Button
                  variant='outlined'>
                  <HelpOutlineIcon sx={{ mr: "15px" }}/>
                  Help & Support
                </Button>

                <Button
                  variant='outlined'
                  onClick={handleLogout}>
                  <LogoutIcon sx={{ mr: "15px" }}/>
                  Log out
                </Button>
              </Box>
            </Box>
        }
      </Container>
    </Box>  
  )
}

export default ProfilePage;