import React from 'react';

import { 
  Box,
  Container, 
  Link, 
  Typography
} from '@mui/material';

import AppTopBar from '../../components/topBar/AppTopBar';

const LoginMessagePage = () => {
  return (
    <Box>
      <AppTopBar />
      <Container 
        maxWidth={"sm"}>
        <Box
          height={"100vh"}
          className="flexCenterCenterColumn">
          <Typography variant='subtitle2'
            mb={"40px"}
            textAlign={"center"}>
            We are in limited release right now. And we want to make sure that you get the right support.
            If you have been invited to preview this release, you will get access to your account shortly. 
          </Typography>
          <Typography variant='subtitle2'
            mb={"40px"}
            textAlign={"center"}>
            Questions? Send email at&nbsp;
            <Link href='https://support.lusid.ai' target="_blank" rel="noopener noreferrer">
              support@lusid.ai
            </Link>
            .
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default LoginMessagePage;