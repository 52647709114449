//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//CRASHES
export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";

//PROFILE
export const GET_PROFILE = "GET_PROFILE";

//CARD
export const GET_CARDS = "GET_CARDS";

//VCN
export const GET_VCNS = "GET_VCNS";
export const GET_VCN_DETAILS = "GET_VCN_DETAILS";
export const CLEAR_VCN_DETAILS = "CLEAR_VCN_DETAILS";

//CIRCLE
export const SEARCH_USERS = "SEARCH_USERS";
export const GET_PENDING_REQUESTS = "GET_PENDING_REQUESTS";
export const GET_CIRCLE_DETAILS = "GET_CIRCLE_DETAILS";

//ASSOCIATIONS
export const GET_WALLETS = "GET_WALLETS";
export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_VCNS = "GET_WALLET_VCNS";
export const GET_ASSOCIATE_WALLETS = "GET_ASSOCIATE_WALLETS";
export const GET_ASSOCIATE_WALLET_DETAILS = "GET_ASSOCIATE_WALLET_DETAILS";

//MISCELLANEOUS
export const GET_CURRENCIES = "GET_CURRENCIES";
export const SELECT_BUCKET = "SELECT_BUCKET";
export const CLEAR_BUCKET = "CLEAR_BUCKET";