const useCurrencyService = () => {
  const commas = (str) => {
    return str?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleCurrency(code, amount){
    try {
      let item;
      amount = parseFloat(amount);
      if( code !== "" || code !== null || code !== undefined ){
        if(localStorage.getItem("CurrencyList") === null){
          return code + " " + commas(amount.toFixed(2));
        }else{
          let list = JSON.parse(localStorage.getItem("CurrencyList"));
          item = list.find(i => i.CharacterCode === code);
          if(item){
            return (item.Symbol === null || item.Symbol === undefined) 
              ? code + " " + commas(amount.toFixed(2))
              : item.Symbol + commas(amount.toFixed(item.Floating));
          }else{
            return code + " " + commas(amount.toFixed(2));
          } 
        }
      }else{
        return code + " " + commas(amount.toFixed(2));
      }
    } catch (err) {
      return code + " " + commas(amount.toFixed(2));
    }
  }

  return [handleCurrency];
}

export default useCurrencyService;