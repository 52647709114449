import * as actionTypes from "../actionTypes.js";

const initialState = {
  currenciesList: null,

  //ERRORS
  errors: null,

  //CRASHES
  crashes: null,

  selectedBucket: null
};

const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENCIES:
      localStorage.setItem("CurrencyList", JSON.stringify(action.payload.Records));
      return { ...state, currenciesList: action.payload };

    // ERRORS
    case actionTypes.SET_ERRORS:
      return { ...state, errors: action.payload };
    case actionTypes.CLEAR_ERRORS:
      return { ...state, errors: null };
    
    //CRASHES
    case actionTypes.SET_CRASHES:
      return { ...state, crashes: action.payload };
    case actionTypes.CLEAR_CRASHES:
      return { ...state, crashes: null };
    
    case actionTypes.SELECT_BUCKET:
      return { ...state, selectedBucket: action.payload };
    case actionTypes.CLEAR_BUCKET:
      return { ...state, selectedBucket: null };
      
    case actionTypes.LOG_OUT:
      return initialState;
   
    default:
      return state;
  }
};

export default miscellaneousReducer;