import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useCookies } from "react-cookie";

const API = axios.create({
  baseURL: `https://api.lusid.ai/b2c/`,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user")).access}`;
  }
  return req;
});

API.interceptors.response.use((response) => {
  if(response.status === 401) {
    alert("You are not authorized");
  }
  return response;
}, (error) => {
  // if(error.response.status === 401){

  // }

  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
})

export const sendRegistrationCode = (form) => API.post(`identity/mobile/v1/user/send-registration-code`, form);
export const resendRegistrationCode = (form) => API.post(`identity/mobile/v1/user/re-send-registration-code`, form);
export const register = (form) => API.post(`identity/mobile/v1/user/register`, form);

export const sendLoginCode = (form) => API.post(`identity/mobile/v1/user/send-login-code`, form);
export const resendLoginCode = (form) => API.post(`identity/mobile/v1/user/re-send-login-code`, form);
export const login = (form) => API.post(`identity/mobile/v1/user/login`, form);

export const refreshAccess = () => API.get(`identity/mobile/v1/user/refreshAccess`);

//profile
export const getProfile = () => API.get(`identity/mobile/v1/user/profile`);
export const updateProfile = (form) => API.patch(`identity/mobile/v1/user/profile/update-names`, form);

//card
export const getCards = (page, limit) => API.get(`payments/mobile/v1/real-cards?page=${page}&limit=${limit}`);
export const addCard = (form) => API.post(`payments/mobile/v1/real-cards`, form);
export const toggleCard = (id) => API.patch(`payments/mobile/v1/real-cards/activate-deactivate/${id}`);

//vcn
export const getVCNs = (page, limit) => API.get(`payments/mobile/v1/virtual-cards/all?page=${page}&limit=${limit}`);
export const getVCNDetails = (id) => API.get(`payments/mobile/v1/virtual-cards/${id}`);
export const sendVCNCode = () => API.post(`identity/mobile/v1/user/otp/virtual-card/generation-code/send`);
export const resendVCNCode = (form) => API.post(`identity/mobile/v1/user/otp/virtual-card/generation-code/re-send`, form);
export const createVCNRealCard = (id, form) => API.post(`payments/mobile/v1/virtual-cards/real-card/${id}`, form);
export const createVCNWallet = (id, form) => API.post(`payments/mobile/v1/virtual-cards/wallet/${id}`, form);
export const deleteVCN = (id) => API.delete(`payments/mobile/v1/virtual-cards/${id}`);

//circle
export const searchUser = (form) => API.post(`identity/mobile/v1/user/find`, form);
export const inviteUser = (form) => API.post(`identity/mobile/v1/user/circle/invite-user`, form);
export const getUserAddRequests = () => API.get(`identity/mobile/v1/user/circle/pending-requests`);
export const requestUserToCircle = (form) => API.post(`identity/mobile/v1/user/circle/new-request`, form);
export const addUserToCricle = (form) => API.post(`identity/mobile/v1/user/circle/add-circle-member`, form);

export const getCircleDetails = () => API.get(`identity/mobile/v1/user/circle`);
export const removeUserFromCircle = (id) => API.delete(`identity/mobile/v1/user/circle/remove-circle-member`, id);

//associations
export const getWallets = (page, limit) => API.get(`payments/mobile/v1/wallets/my?page=${page}&limit=${limit}`);
export const getWalletDetails = (id) => API.get(`payments/mobile/v1/wallets/my/info/${id}`);
export const getWalletsVCNs = (userId, walletId, page, limit) => 
  API.get(`payments/mobile/v1/wallets/get-vcns/${userId}/${walletId}?page=${page}&limit=${limit}`);
export const createWallet = (form) => API.post(`payments/mobile/v1/wallets`, form);
export const deleteWallet = (associateUserId, walletId) => 
  API.delete(`payments/mobile/v1/wallets/for/${associateUserId}/${walletId}`);

export const getAssociateWallets = (id, page, limit) => 
  API.get(`payments/mobile/v1/wallets/for/${id}?page=${page}&limit=${limit}`);
export const getAssociateWalletsDetails = (associateUserId, walletId) => 
  API.get(`payments/mobile/v1/wallets/for/${associateUserId}/info/${walletId}`);

export const updateWallets = (associateUserId, walletId, walletConfigId, form) => 
  API.patch(`payments/mobile/v1/wallets/for/${associateUserId}/${walletId}/controls/${walletConfigId}`, form);

//miscellaneous
export const getCurrencies = (page, limit) => API.get(`payments/mobile/v1/currency?page=${page}&limit=${limit}`);