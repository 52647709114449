import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import { 
  AddCard, 
  GetCards 
} from '../../redux/actions/cardActions';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  Alias: "",
  Last4Digits: "",
  CurrencyCode: "USD"
}

const CardsModal = ({open, setOpen, title, update, page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setMode(-1);
    setFormData(Data);
  }

  useEffect(() => {
    if(open){
      if(title === "Add Real Card"){
        // mode == 1 - add
        setMode(1);
      }else if(title === "Edit Real Card"){
        // mode == 2 - edit
        setMode(2);
        setFormData({
          ...Data, 
          Alias: update.Alias,
          Last4Digits: update.Last4Digits
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  const handleChange = (e) => {
    if(e.target.name === "Last4Digits"){
      setFormData({ ...formData, [e.target.name]: e.target.value.replace(/[^0-9]/g, '') });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }

  const handleCreateCard = () => {
    if(mode === 1){
      // create
      dispatch(AddCard(formData, setLoading)).then(() => {
        dispatch(GetCards(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      })
    }else if(mode === 2){
      // edit

    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px" }}
          className="modal">
          <Box width={"100%"}
            sx={{ margin: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }}>
              {title}
            </Typography>
          </Box>
          {
            mode === 1
            ?
            <Box margin={"20px 15px"} className="flexCenter_Row" gap={"20px"}>
              <TextField
                variant='outlined'
                name='Alias'
                label='Alias'
                autoFocus={true}
                fullWidth={true}
                inputProps={{ maxLength: 20 }}
                value={formData.Alias}
                onChange={handleChange}
                />
              <TextField
                variant='outlined'
                name='Last4Digits'
                label='Last 4 digits'
                fullWidth={true}
                inputProps={{ maxLength: 4 }}
                value={formData.Last4Digits}
                onChange={handleChange}
                />
            </Box>
            : 
              mode === 2
              ?
              <Box>

              </Box>
              :
              <Typography variant='body1'>
                Something went wrong
              </Typography>
          }
          <Divider
          sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              disabled={
                !(formData.Alias !== "" && formData.Last4Digits.length > 3)
              }
              loading={loading}
              onClick={handleCreateCard}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CardsModal