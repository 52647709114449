import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const GetWallets = (page, limit, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getWallets(page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_WALLETS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetWalletDetails = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getWalletDetails(id);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_WALLET_DETAILS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetWalletsVCNs = (userId, walletId, page, limit, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getWalletsVCNs(userId, walletId, page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_WALLET_VCNS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const CreateWallet = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.createWallet(formData);
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const DeleteWallet = (associateUserId, walletId, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.deleteWallet(associateUserId, walletId);
    if (status === 200) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetAssociateWallets = (id, page, limit, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getAssociateWallets(id, page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_ASSOCIATE_WALLETS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetAssociateWalletsDetails = (associateUserId, walletId, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getAssociateWalletsDetails(associateUserId, walletId);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_ASSOCIATE_WALLET_DETAILS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const UpdateWallets = (associateUserId, walletId, walletConfigId, formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.updateWallets(associateUserId, walletId, walletConfigId, formData);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(data.message);
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};


