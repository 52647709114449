import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Divider,
  Modal, 
  Skeleton, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import { 
  AddUserToCricle,
  GetCircleDetails,
  GetUserAddRequests,
  RequestUserToCircle,
  SearchUser 
} from '../../redux/actions/circleActions';

import EnterCodeModal from './EnterCodeModal';

import style from "../../global.scss";
import "./styles.scss";

const AddMemberModal = ({open, setOpen, title}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.circle);
  const profile = useSelector(state => state.profile);
  const [loading, setLoading] = useState(false);
  const [enterCodeModalOpen, setEnterCodeModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState({});
  
  const [requesting, setRequesting] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [userId, setUserId] = useState(null);

  const [query, setQuery] = useState("");
  const [code, setCode] = useState("");

  const [usersList, setUsersList] = useState([]);

  const [selfUserId, setSelfUserId] = useState(null);

  useEffect(() => {
    if(open){
    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setUsersList([]);
    setQuery("");
  }

  useEffect(() => {
    // if(query.length > 2){
      dispatch(SearchUser({ Name: query }, setLoading))
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    try {
      formatUsersList(state.searchUsersList.Records || []);
    } catch (err) {}
  }, [state]);

  useEffect(() => {
    try {
      setSelfUserId(profile.profile.Profile.ContactInfo.Mobile.CountryCode + 
        profile.profile.Profile.ContactInfo.Mobile.Number);
    } catch (err) {}
  }, [profile]);

  // ------------------------ list formatters ------------------------
  const formatUsersList = (list) => {
    setUsersList(list);
  }

  const handleRequestUser = (id) => {
    setUserId(id);
    setCode("");
    dispatch(RequestUserToCircle({ReceiverId: id}, setRequesting)).then(() => {
      // setRequestId(null);
      dispatch(GetUserAddRequests(setRequesting)).then((res) => {
        if(res.error === false){
          setRequestId(res.data.Records[0].Id);
          setEnterCodeModalOpen(true);
        }
      })
    })
  }

  const handleAddUserToCircle = () => {
    setEnterCodeModalOpen(false);
    // setRequestId(update.Id);

    let obj = {
      RequestId: requestId,
      ReceiverId: userId,
      CircleCode: code
    }

    dispatch(AddUserToCricle(obj, setRequesting)).then(() => {
      dispatch(GetCircleDetails(setRequesting)).then(() => {
        handleClose();
      })
      // dispatch(GetUserAddRequests(setRequesting)).then(() => {
      // })
    })
  }

  return (
    <>
    <EnterCodeModal
      open={enterCodeModalOpen}
      setOpen={setEnterCodeModalOpen}
      title={modalTitle}
      code={code}
      setCode={setCode}
      handleSubmit={handleAddUserToCircle}
      />
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "sm", margin: "10px", height: "80vh" }}
          className="modal flex_SBColumn">
          <Box>
            <Box width={"100%"}
              sx={{ margin: "15px 15px 30px 15px"}}>
              <Typography variant='h6' sx={{ color: style["secondary"] }}>
                {title}
              </Typography>
            </Box>
            <Box margin={"20px 15px"} className="flexCenter_Row" gap={"20px"}>
              <TextField
                variant='outlined'
                label='Search'
                placeholder='Enter name of your contact'
                autoFocus={true}
                fullWidth={true}
                inputProps={{ maxLength: 20 }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                />
            </Box>

            {
              loading
              ?
              <Box p={"15px"}>
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                <Skeleton
                  variant="rectangular" 
                  animation="wave"
                  width={"100%"} height={"83px"} 
                  sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
              </Box>
              :
                usersList.length === 0
                ?
                <Box className="flexCenterCenterRow" height={"200px"}>
                  <Typography>No user found</Typography>
                </Box>
                :
                <Box p={"15px"}
                  display={"flex"} flexDirection={"column"}
                  gap={"15px"}>
                  {
                    usersList.map((item, index) => (
                      <Box key={index} className="user flexCenterSBRow">
                        <Box>
                          <Typography>
                            {item.Name}&nbsp;
                            {
                              selfUserId === (item.MobileCountryCode + item.MobileNumber)
                              &&
                              <b>(me)</b>
                            }
                          </Typography>
                          <Typography color={"grey"}>
                            +{item.MobileCountryCode}&nbsp;{item.MobileNumber}
                          </Typography>
                        </Box>
                        {
                          selfUserId !== (item.MobileCountryCode + item.MobileNumber)
                          &&
                          <LoadingButton
                            variant="text"
                            onClick={() => handleRequestUser(item.Id)}
                            loading={
                              userId === item.Id && requesting ? true : false
                            }>
                            Add
                          </LoadingButton>
                        }
                      </Box>
                    ))
                  }
                </Box>
            }
          </Box>
          <Box>
            <Divider
            sx={{ backgroundColor: style["primary_light"] }}/>
            <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px" }}>
              <Button variant='outlined'
                onClick={handleClose}>
                Close
              </Button>
              {/* <LoadingButton 
                variant='contained'
                disabled={
                  !(formData.Alias !== "" && formData.Last4Digits.length > 3)
                }
                loading={loading}
                onClick={handleCreateCard}>
                Submit
              </LoadingButton> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
    </>
  )
}

export default AddMemberModal;