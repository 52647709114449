import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Modal,  
  Skeleton, 
  Step, 
  StepLabel, 
  Stepper, 
  TextField, 
  ToggleButton, 
  ToggleButtonGroup, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';
import MasterCardLogo from '../../assets/icons/mastercard-logo.svg';
import useCurrencyService from '../../components/utils/CurrencyService';
import BucketTypeChip from '../../components/utils/BucketTypeChip';

import ClearIcon from '@mui/icons-material/Clear';

import { 
  GetCards 
} from '../../redux/actions/cardActions';
import { 
  CreateVCNRealCard,
  CreateVCNWallet,
  GetVCNs,
  ResendVCNCode,
  SendVCNCode 
} from '../../redux/actions/vcnActions';
import { 
  GetWallets 
} from '../../redux/actions/associationActions';

import OtpModal from './OtpModal';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  Amount: "",
  Description: "",
  Bearer: "",
  Expiry: "2023-12-22T18:30:00.000Z",
  OTPReferenceId: "",
  Code: ""
}

const VirtualCardsModal = ({open, setOpen, title, page, rowsPerPage}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.card);
  const associate = useSelector(state => state.associate);
  const [handleCurrency] = useCurrencyService();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [otpModalOpen, setOtpModalOpen] = useState(false);

  const [otp, setOtp] = useState("");

  const [cardsList, setCardsList] = useState([]);
  const [walletsList, setWalletsList] = useState([]);

  const [selectedSource, setSelectedSource] = useState(null);
  const [sourceType, setSourceType] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [validTill, setValidTill] = useState(1);
  const [customValidTillType, setCustomValidTillType] = useState(1);
  const [customValidTillValue, setCustomValidTillValue] = useState(null);

  const [allowResendCode, setAllowResendCode] = useState(false);

  const [startTime, setStartTime] = useState(Date.now());

  const handleClose = () => {
    setOpen(false);
    setSelectedSource(null);
    setSourceType(null);
    setSelectedWallet(null);
    setValidTill(1);
    setCustomValidTillType(1);
    setCustomValidTillValue(null);
    setFormData(Data);
    setActiveStep(0);
    setStartTime(Date.now());
  }

  useEffect(() => {
    if(open){
      dispatch(GetCards(1, 100, setContentLoading));
      dispatch(GetWallets(1, 100, setContentLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatCardsList(state.cardsList.Records || []);
    } catch (err) { }
  }, [state]);

  useEffect(() => {
    try {
      formatWalletsList(associate.myWalletsList.Records || []);
    } catch (err) {}
  }, [associate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleCreateVirtualCard = () => {
    // calc valid till time
    setOtpModalOpen(false);

    let obj = {
      Amount: formData.Amount,
      Description: formData.Description,
      Bearer: "",
      Expiry: new Date(Date.now() + calculateExpiryTime()).toISOString(),
      OTPReferenceId: formData.OTPReferenceId,
      Code: otp
    }

    if(sourceType === "card"){
      dispatch(CreateVCNRealCard(selectedSource, obj, setLoading)).then(() => {
        dispatch(GetVCNs(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(sourceType === "wallet"){
      dispatch(CreateVCNWallet(selectedSource, obj, setLoading)).then(() => {
        dispatch(GetVCNs(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      });
    }else {
      toast.error("Something went wrong");
    }
  }

  // ------------------------- list formatter --------------------
  const formatCardsList = (list) => {
    setCardsList(list.filter(i => i.Status === true));
  }

  const formatWalletsList = (list) => {
    setWalletsList(list.filter(i => i.Status === "active"));
  }

  // ------------------------- stepper ---------------------------
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ['Source', 'Details', 'Controls'];

  // ------------------------- otp ------------------------------
  const handleSendVCNVerifyCode = () => {
    dispatch(SendVCNCode(setLoading)).then(({res, statusCode}) => {
      setFormData({ ...formData, OTPReferenceId: res.data.ReferenceId });
      setOtp("");
      setInitialTime();
      setOtpModalOpen(!otpModalOpen);
    })
  }

  const handleResendVCNVerifyCode = () => {
    setAllowResendCode(false);
    dispatch(ResendVCNCode({ ReferenceId: formData.OTPReferenceId}, setLoading));
    setInitialTime();
    setOtp("");
  }

  // ------------------------- miscellaneous ---------------------
  const calculateExpiryTime = () => {
    try {
      let additionalTime = 0;
      if(validTill === 1){
        // 15 mins
        additionalTime = 15 * 60 * 1000
      }else if(validTill === 2){
        // 1 day
        additionalTime = 24 * 60 * 60 * 1000
      }else if(validTill === 3){
        // custom time
        if(customValidTillType === 1){
          // custom minutes
          additionalTime = parseInt(customValidTillValue) * 60 * 1000
        }else if(customValidTillType === 2){
          // custom hours
          additionalTime = parseInt(customValidTillValue) * 60 * 60 * 1000
        }else if(customValidTillType === 3){
          // custom days
          additionalTime = parseInt(customValidTillValue) * 24 * 60 * 60 * 1000
        }else if(customValidTillType === 4){
          // custom months
          additionalTime = parseInt(customValidTillValue) * 30 * 24 * 60 * 60 * 1000
        }else{
          toast.error("Something went wrong");
        }
      }else{
        toast.error("Something went wrong");
      }
  
      return additionalTime;
    } catch (err) {
      toast.error("Something went wrong");
    }
  }

  const setInitialTime = () => {
    setStartTime(Date.now() + 30000);
  }

  return (
    <>
    
    <OtpModal
      open={otpModalOpen}
      setOpen={setOtpModalOpen}
      title={"OTP verification"}
      otp={otp}
      setOtp={setOtp}
      allowResendCode={allowResendCode}
      setAllowResendCode={setAllowResendCode}
      handleSubmit={handleCreateVirtualCard}
      handleResend={handleResendVCNVerifyCode}
      startTime={startTime}
      />

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          width={"100vw"} height={"100vh"}
          sx={{ borderRadius: "0px" }}
          className="modal flex_SBColumn">
          <Box>
            <Box width={"100%"}
              className="flexCenter_Row"
              sx={{ margin: "15px 15px 30px 15px", gap: "15px" }}>
              <IconButton
                onClick={handleClose}>
                <ClearIcon 
                  sx={{ color: "grey", fontSize: "40px" }}/>
              </IconButton>
              <Typography variant='h5' sx={{ color: style["secondary"] }}>
                {title}
              </Typography>
            </Box>
            <Box
              height={"100%"}
              className="flexCenterCenterRow"
              padding={"20px"}>
              <Box
                sx={{
                  width: "100%", 
                  maxWidth: "md",
                  margin: "auto", 
                  height: "70vh" }}
                  className="flex_SBColumn">
                <Box>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {
                    activeStep === 0
                    ?
                      <>
                        <Typography variant='subtitle1' textAlign={"center"}
                          mt={"20px"} mb={"40px"}>
                          Select virtual card balance source
                        </Typography>
                        { 
                          contentLoading
                          ?
                          <>
                            <Skeleton
                              variant="rectangular" 
                              animation="wave"
                              width={"220px"} height={"38px"} 
                              mb={"20px"}
                              sx={{ borderRadius: "2px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                            <Box className="flexCenterSBRow" gap={"16px"}>
                              <Skeleton
                                variant="rectangular" 
                                animation="wave"
                                width={"300px"} height={"120px"} 
                                sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                              <Skeleton
                                variant="rectangular" 
                                animation="wave"
                                width={"300px"} height={"120px"} 
                                sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                              <Skeleton
                                variant="rectangular" 
                                animation="wave"
                                width={"300px"} height={"120px"} 
                                sx={{ borderRadius: "16px", bgcolor: 'grey.100', marginBottom: "15px" }} />
                            </Box>
                          </>
                          :
                            <Box
                              sx={{ overflow: "auto", maxHeight: "500px", 
                                padding: "10px" }}>
                            {
                              cardsList.length === 0
                              ?
                              <></>
                              :
                                <Box>
                                  <Typography variant='h6' mb={"20px"}>
                                    Real Cards
                                  </Typography>
                                  <Grid container
                                    gap={2}>
                                    {
                                      cardsList.map((item, index) => (
                                        <Grid item key={index} 
                                          className={`vcn__creditcard ${selectedSource === item.Id && 'vcn__creditcard-selected'}`}
                                          lg={3.8} md={3.8} sm={5.8} xs={12}
                                          onClick={() => {
                                            setSourceType("card");
                                            setSelectedSource(item.Id);
                                            }}>
                                          <Box className="flexCenterSBRow">
                                            <Typography variant='subtitle2'>{item.Alias}</Typography>
                                            <Typography>{item.Currency}</Typography>
                                          </Box>
                                          <Box className="flexCenterSBRow">
                                            <Typography>xxxx&nbsp;xxxx&nbsp;xxxx&nbsp;{item.Last4Digits}</Typography>
                                          </Box>
                                          <Box className="flexCenterFERow" mt={"5px"}>
                                            <img
                                              src={MasterCardLogo}
                                              alt='mastercard-logo'
                                              style={{ width: "45px" }}/>
                                          </Box>
                                        </Grid>
                                      ))
                                    }
                                  </Grid>
                                </Box>
                              }
                              {
                                walletsList.length === 0
                                ?
                                <></>
                                :
                                  <Box>
                                    <Typography variant='h6' mb={"20px"} mt={"30px"}>
                                      Wallets
                                    </Typography>
                                    <Grid container
                                      gap={2}>
                                      {
                                        walletsList.map((item, index) => (
                                          <Grid item key={index} 
                                            className={`vcn__creditcard ${selectedSource === item.Id && 'vcn__creditcard-selected'}`}
                                            lg={3.8} md={3.8} sm={5.8} xs={12}
                                            onClick={() => {
                                              setSelectedWallet(item);
                                              setSourceType("wallet");
                                              setSelectedSource(item.Id);
                                              }}>
                                            <Box className="flexCenterSBRow">
                                              <Typography variant='subtitle2'>{item.Alias}</Typography>&nbsp;
                                              <BucketTypeChip>
                                                {item.Type}
                                              </BucketTypeChip>
                                            </Box>
                                            <Box className="flexCenterSBRow" mt={"3px"}>
                                              <Box className="flexCenter_Row">
                                                {/* <Typography variant='body2'>Balance:</Typography>&nbsp; */}
                                                <Typography fontWeight={600}>
                                                  {handleCurrency(item.Currency, item.AvailableLimit) || "NA"}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box className="flexCenterSBRow">
                                              <Box className="flexCenter_Row">
                                                <Typography variant='body2'>Expiry:</Typography>&nbsp;
                                                <Typography>{item.ExpiresOn ? new Date(item.ExpiresOn).toDateString().slice(4) : "NA"}</Typography>
                                              </Box>
                                              <img
                                                src={MasterCardLogo}
                                                alt='mastercard-logo'
                                                style={{ width: "45px" }}/>
                                            </Box>
                                          </Grid>
                                        ))
                                      }
                                    </Grid>
                                  </Box>
                              }
                              {  
                                (cardsList.length === 0 && walletsList.length === 0)
                                &&
                                <Box className="flexCenterCenterRow" height={"200px"}>
                                  <Typography>No real card or wallet found</Typography>
                                </Box>
                              }
                              </Box>            
                          }
                      </>
                    :
                      activeStep === 1
                      ?
                      <>
                        <Typography variant='subtitle1' textAlign={"center"}
                          mt={"20px"} mb={"40px"}>
                          Enter virtual card amount and description
                        </Typography>
                        <Box 
                          width={"100%"}
                          maxWidth={"400px"}
                          margin={"auto"} paddingTop={"40px"}
                          className="flexCenterCenterColumn" 
                          gap={"20px"}>
                          <TextField 
                            variant='outlined'
                            type='number'
                            name='Amount'
                            label='Amount'
                            autoFocus={true}
                            placeholder='Amount of Virtual Card'
                            fullWidth={true}
                            inputProps={{ maxLength: 8 }}
                            value={formData.Amount}
                            onChange={handleChange}
                            error={
                              sourceType === "wallet" 
                              ? formData.Amount > selectedWallet.AvailableLimit
                              : false
                            }
                            helperText={
                              sourceType === "wallet" 
                              ? `Available Limit: ${ <Typography fontWeight={600}>{handleCurrency(selectedWallet.Currency, selectedWallet.AvailableLimit)}</Typography> || "NA"}`
                              : ""}
                            />
                          <TextField 
                            variant='outlined'
                            name='Description'
                            label='Description (optional)'
                            placeholder='Description of Virtual Card'
                            fullWidth={true}
                            value={formData.Description}
                            inputProps={{ maxLength: 64 }}
                            onChange={handleChange}/>
                        </Box>
                      </>
                      :
                      <>
                        <Typography variant='subtitle1' textAlign={"center"}
                          mt={"20px"} mb={"40px"}>
                          Set controls for this virtual card
                        </Typography>
                        <Box 
                          width={"100%"}
                          maxWidth={"400px"}
                          margin={"auto"} paddingTop={"40px"}
                          className="flexCenterCenterColumn" 
                          gap={"20px"}>
                          <Box width={"100%"}>
                            <Typography variant='h6' mb="20px">
                              Valid till
                            </Typography>
                            <Box
                              className="flexCenterSBRow"
                              gap={"20px"}>
                              <ToggleButtonGroup
                                color="primary"
                                value={validTill+""}
                                fullWidth
                                exclusive
                                onChange={(e) => setValidTill(parseInt(e.target.value))}
                                aria-label="Platform"
                              >
                                <ToggleButton value={"1"}>15 Minutes</ToggleButton>
                                <ToggleButton value={"2"}>1 Day</ToggleButton>
                                <ToggleButton value={"3"}>Custom</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                            {
                              validTill === 3
                              &&
                              <Box mt={"30px"}>
                                <Typography variant='subtitle2' mb={"10px"}>Select custom time</Typography>
                                <Box className="flexFSSBRow" gap={"20px"}>

                                  <TextField
                                    select
                                    sx={{ width: "40%" }}
                                    value={customValidTillType}
                                    onChange={(e) => setCustomValidTillType(e.target.value)}>
                                    <MenuItem 
                                      value={1}>
                                      Minutes
                                    </MenuItem>
                                    <MenuItem 
                                      value={2}>
                                      Hours
                                    </MenuItem>
                                    <MenuItem 
                                      value={3}>
                                      Days
                                    </MenuItem>
                                    <MenuItem 
                                      value={4}>
                                      Months
                                    </MenuItem>
                                  </TextField>
                                  <TextField 
                                    sx={{ width: "60%" }}
                                    variant='outlined'
                                    name='flexFSSBRow'
                                    autoFocus={true}
                                    label='Value'
                                    placeholder={
                                      customValidTillType === 1 ? "Number of minutes" :
                                        customValidTillType === 2 ? "Number of hours" :
                                          customValidTillType === 3 ? "Number of days" :
                                            customValidTillType === 4 ? "Number of months" : ""
                                    }
                                    fullWidth={true}
                                    value={customValidTillValue}
                                    inputProps={{ maxLength: 64 }}
                                    type="number"
                                    onChange={(e) => {
                                      let str = e.target.value.replace(".", "").replace("e", "");
                                      setCustomValidTillValue(str);
                                    }}
                                    error={
                                      customValidTillType === 1 ? customValidTillValue >= 1 && customValidTillValue <= 60 ? false : true :
                                        customValidTillType === 2 ? customValidTillValue >= 1 && customValidTillValue <= 24 ? false : true :
                                          customValidTillType === 3 ? customValidTillValue >= 1 && customValidTillValue <= 365 ? false : true :
                                            customValidTillType === 4 ? customValidTillValue >= 1 && customValidTillValue <= 12 ? false : true : false
                                    }
                                    helperText={
                                      customValidTillType === 1 ? "Valid range is 1 to 60" :
                                        customValidTillType === 2 ? "Valid range is 1 to 24" :
                                          customValidTillType === 3 ? "Valid range is 1 to 365" :
                                            customValidTillType === 4 ? "Valid range is 1 to 12" : ""
                                    }
                                    />
                                </Box>
                              </Box>
                            }
                          </Box>
                        </Box>
                      </>
                  }
                </Box>
                <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
                  {
                    activeStep > 0 &&
                    <Button variant='outlined'
                      onClick={handleBack}>
                      Back
                    </Button>
                  }
                  <LoadingButton 
                    variant={activeStep < 2 ? 'text' : 'contained'}
                    loading={loading}
                    disabled={
                      activeStep === 0
                      ? selectedSource === null ? true : false
                      : activeStep === 1
                        ? sourceType === "wallet" 
                          ? (formData.Amount > 0 && formData.Amount <= selectedWallet.AvailableLimit) ? false : true
                          : formData.Amount > 0 ? false : true
                        : validTill === 3 
                          ? customValidTillType === 1 ? customValidTillValue >= 1 && customValidTillValue <= 60 ? false : true :
                              customValidTillType === 2 ? customValidTillValue >= 1 && customValidTillValue <= 24 ? false : true :
                                customValidTillType === 3 ? customValidTillValue >= 1 && customValidTillValue <= 365 ? false : true :
                                  customValidTillType === 4 ? customValidTillValue >= 1 && customValidTillValue <= 12 ? false : true : false
                          :
                          false
                    }
                    onClick={() => {
                      if(activeStep === 2){
                        handleSendVCNVerifyCode();
                      }else{
                        handleNext();
                      }
                    }}>
                    {activeStep < 2 ? 'Next' : 'Create'}
                  </LoadingButton>
                </Box> 
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
    </>
  )
}

export default VirtualCardsModal;