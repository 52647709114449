import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Modal, 
  Switch, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../components/buttons/Button';

import { 
  GetAssociateWalletsDetails, 
  UpdateWallets 
} from '../../redux/actions/associationActions';

import style from "../../global.scss";
import "./styles.scss";

const BucketInfoModal = ({open, setOpen, title, userId, walletId}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.associate);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [toggling, setToggling] = useState(false);

  const [walletInfo, setWalletInfo] = useState(null);

  const [customValidTillDisabled, setCustomValidTillDisabled] = useState(true);
  const [customValidTillType, setCustomValidTillType] = useState(1);
  const [customValidTillValue, setCustomValidTillValue] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  }

  useEffect(() => { 
    if(open) {
      dispatch(GetAssociateWalletsDetails(userId, walletId, setLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatWalletObj(state.associateWalletDetails || null);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // -------------------- object formatter --------------------
  const formatWalletObj = (obj) => {
    setWalletInfo(obj);
    setCustomValidTillDisabled(true);

    // set valid for 
    if(obj?.Virtual_Card_Controls?.AllowValidFor === false){
      formatValidTillTime(obj?.Virtual_Card_Controls.ValidFor);
    }
  }

  const formatValidTillTime = (timeStamp) => {
    if(timeStamp >= 86400000) {
      // in days
      setCustomValidTillType(3);
      setCustomValidTillValue(timeStamp / 24 / 60 / 60 / 1000);
    }else if(timeStamp >= 3600000){
      // in hours
      setCustomValidTillType(2);
      setCustomValidTillValue(timeStamp / 60 / 60 / 1000);
    }else if(timeStamp >= 60000){
      // in minutes
      setCustomValidTillType(1);
      setCustomValidTillValue(timeStamp / 60 / 1000);
    }else{
      // default
      setCustomValidTillType(2);
      setCustomValidTillValue(1);
    }
  }

  const handleToggle = (type, bool) => {
    let obj = {
      "Controls": {
        "Usage": {
          "Domestic": type === "domestic" ? bool : walletInfo.Usage_Controls.Domestic,
          "International": type === "international" ? bool : walletInfo.Usage_Controls.International,
        },
        "VCN": {
          "AllowValidFor": type === "allowValidFor" ? bool : walletInfo.Virtual_Card_Controls?.AllowValidFor ? walletInfo.Virtual_Card_Controls.AllowValidFor : false ,
          "ValidFor": type === "validFor" ? walletInfo.Virtual_Card_Controls?.AllowValidFor === false ? calcaluteValidTillTime() : 86400000 : 
           walletInfo.Virtual_Card_Controls?.ValidFor,
        }
      }
    }

    dispatch(UpdateWallets(userId, walletId, walletInfo.Id, obj, setToggling)).then(() => {
      dispatch(GetAssociateWalletsDetails(userId, walletId, setToggling));
    });
  }

  const calcaluteValidTillTime = () => {
    let timeStamp = "";

    if(customValidTillType === 1){
      // minutes
      timeStamp = customValidTillValue * 60 * 1000;
    }else if(customValidTillType === 2){
      // hours
      timeStamp = customValidTillValue * 60 * 60 * 1000;
    }else if(customValidTillType === 3){
      // days
      timeStamp = customValidTillValue * 24 * 60 * 60 * 1000;
    }else{
      toast.error("Something went wrong")
    }

    return timeStamp;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box sx={{ maxWidth: "sm", margin: "10px" }}
        className="confirm flex_SBColumn">
          <Box width={"100%"}
            sx={{ padding: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["secondary"] }} mb={'20px'}>
              {title}
            </Typography>
            {
              loading
              ?
              <Box height={"480px"} className="flexCenterCenterRow">
                <CircularProgress />
              </Box>
              :
                walletInfo === null
                ?
                <Box height={"480px"} className="flexCenterCenterRow">
                  <Typography variant='subtitle1'>Something went wrong</Typography>
                </Box>
                :
                <Box height={"480px"}>
                  <Box mb={"30px"}>
                    <Typography variant='subtitle1'>Usage Controls</Typography>
                    <Box className="flexCenterSBRow" mb={'10px'} 
                      sx={{ backgroundColor: "#fafafa", padding: "10px 20px", borderRadius: "12px" }}>
                      <Typography>Domestic</Typography>
                      <Switch 
                        checked={walletInfo.Usage_Controls.Domestic}
                        onChange={(e) => {
                          handleToggle("domestic", e.target.checked);
                        }}
                        />
                    </Box>
                    <Box className="flexCenterSBRow" mb={'30px'} 
                      sx={{ backgroundColor: "#fafafa", padding: "10px 20px", borderRadius: "12px" }}>
                      <Typography>International</Typography>
                      <Switch 
                        checked={walletInfo.Usage_Controls.International}
                        onChange={(e) => {
                          handleToggle("international", e.target.checked);
                        }}
                        />
                    </Box>
                    <Typography variant='subtitle1'>Virtual Card Controls</Typography>
                    <Box className="flexCenterSBRow" mb={'10px'} 
                      sx={{ backgroundColor: "#fafafa", padding: "10px 20px", borderRadius: "12px" }}>
                      <Typography>Allow circle member to set virtual card expiry</Typography>
                      <Switch 
                        checked={walletInfo.Virtual_Card_Controls?.AllowValidFor}
                        onChange={(e) => {
                          handleToggle("allowValidFor", e.target.checked);
                        }}
                        />
                    </Box>
                    {
                      walletInfo.Virtual_Card_Controls?.AllowValidFor === false
                      &&
                      <Box className="" mb={'30px'} 
                        sx={{ backgroundColor: "#fafafa", padding: "10px 20px", borderRadius: "12px" }}>
                        <Typography>Default virtual card expiry</Typography>&nbsp;
                        <Box className="flexFSSBRow" gap={"20px"}>
                          <TextField
                            select
                            sx={{ width: "40%" }}
                            value={customValidTillType}
                            onChange={(e) => {
                              setCustomValidTillDisabled(false);
                              setCustomValidTillType(e.target.value);
                            }}>
                            <MenuItem 
                              value={1}>
                              Minutes
                            </MenuItem>
                            <MenuItem 
                              value={2}>
                              Hours
                            </MenuItem>
                            <MenuItem 
                              value={3}>
                              Days
                            </MenuItem>
                          </TextField>
                          <TextField 
                            sx={{ width: "60%" }}
                            variant='outlined'
                            name='flexFSSBRow'
                            label='Value'
                            placeholder={
                              customValidTillType === 1 ? "Number of minutes" :
                                customValidTillType === 2 ? "Number of hours" :
                                  customValidTillType === 3 ? "Number of days" : ""
                            }
                            fullWidth={true}
                            value={customValidTillValue}
                            inputProps={{ maxLength: 64 }}
                            type="number"
                            onChange={(e) => {
                              setCustomValidTillDisabled(false);
                              let str = e.target.value.replace(".", "").replace("e", "");
                              setCustomValidTillValue(str);
                            }}
                            error={
                              customValidTillType === 1 ? customValidTillValue >= 1 && customValidTillValue <= 60 ? false : true :
                                customValidTillType === 2 ? customValidTillValue >= 1 && customValidTillValue <= 24 ? false : true :
                                  customValidTillType === 3 ? customValidTillValue >= 1 && customValidTillValue <= 7 ? false : true : false
                            }
                            helperText={
                              customValidTillType === 1 ? "Valid range is 1 to 60" :
                                customValidTillType === 2 ? "Valid range is 1 to 24" :
                                  customValidTillType === 3 ? "Valid range is 1 to 7" : ""
                            }
                            />
                          <LoadingButton
                            variant={"contained"}
                            disabled={
                              customValidTillDisabled ||
                              (customValidTillType === 1 ? customValidTillValue >= 1 && customValidTillValue <= 60 ? false : true :
                                customValidTillType === 2 ? customValidTillValue >= 1 && customValidTillValue <= 24 ? false : true :
                                  customValidTillType === 3 ? customValidTillValue >= 1 && customValidTillValue <= 7 ? false : true : false)
                            }
                            onClick={() => handleToggle("validFor", false)}>
                            Update
                          </LoadingButton>
                        </Box>
                        {/* <Typography>

                          {walletInfo.Virtual_Card_Controls.ValidFor}
                        </Typography> */}
                      </Box>
                    }
                  </Box>
                  {/* <Typography color={"grey"} variant='body2'>
                    <i>Ask your wallet provider to change wallet settings.</i>
                  </Typography> */}
                </Box>
            }
          </Box>
          <Divider
          sx={{ backgroundColor: style["primary_light"] }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px" }}>
            <Button variant='outlined'
              onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default BucketInfoModal;