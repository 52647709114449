import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { 
  Box, 
  Typography
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import SideNavigation from './SideNavigation';
import SideBarLogo from '../utils/SideBarLogo';
import TopNavigation from './TopNavigation';

import "./styles.scss";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [isNavExpanded, setIsNavExpanded] = useState(localStorage.getItem("navExpanded"));

  useEffect(() => {
    var route = location.pathname.slice(1);    

    let subRoutes = route.split("/");

    if(subRoutes.length === 3){
      setCurrentPage(4);
    }else{
      switch(route){
        case "": return setCurrentPage(0);
        case "cards": return setCurrentPage(1);
        // case "vcn": return setCurrentPage(2);
        case "circles": return setCurrentPage(3);
        case "buckets": return setCurrentPage(4);
  
        default: return setCurrentPage(0);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, location.pathname]);


  const BottomNavigation = () => {
    return (
      <Box className="nav__bottom"
        sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
        <Box className="flexCenterSERow" sx={{ height: "100%" }}>
          <Box className={`${currentPage === 0 ? 'nav__bottom-selected' : 'nav__bottom-item'} flexCenterCenterColumn`}
            onClick={() => {
              navigate("/");
              setCurrentPage(0);
            }}>
            <HomeOutlinedIcon />
            <Typography variant='body2'>
              Home
            </Typography>
          </Box>
          <Box className={`${currentPage === 1 ? 'nav__bottom-selected' : 'nav__bottom-item'} flexCenterCenterColumn`}
            onClick={() => {
              navigate("/cards");
              setCurrentPage(1);
            }}>
            <CreditCardIcon />
            <Typography variant='body2'>
              Cards
            </Typography>
          </Box>
          <Box className={`${currentPage === 2 ? 'nav__bottom-selected' : 'nav__bottom-item'} flexCenterCenterColumn`}
            onClick={() => {
              navigate("/vcn");
              setCurrentPage(2);
            }}>
            <CreditCardIcon />
            <Typography variant='body2'>
              Virtual Cards
            </Typography>
          </Box>
          <Box className={`${currentPage === 3 ? 'nav__bottom-selected' : 'nav__bottom-item'} flexCenterCenterColumn`}
            onClick={() => {
              navigate("/circles");
              setCurrentPage(3);
            }}>
            <GroupsOutlinedIcon />
            <Typography variant='body2'>
              Circles
            </Typography>
          </Box>
          <Box className={`${currentPage === 4 ? 'nav__bottom-selected' : 'nav__bottom-item'} flexCenterCenterColumn`}
            onClick={() => {
              navigate("/wallets");
              setCurrentPage(4);
            }}>
            <AccountBalanceWalletOutlinedIcon />
            <Typography variant='body2'>
              Wallets
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className="nav">
      <Box display={"flex"}
        sx={{ 
          height: "100vh" }}>
        <SideBarLogo 
          isNavExpanded={isNavExpanded} />
        <SideNavigation
          currentPage={currentPage}
          isNavExpanded={isNavExpanded}
          setIsNavExpanded={setIsNavExpanded} />
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            height: "calc(100vh - 0px)",
            width: isNavExpanded ? "calc(100% - 200px)" : "100%"
          }}>
          <TopNavigation />
          <Box 
            padding={"30px 30px 0px 30px"}
            sx={{
            height: "100%",
            backgroundColor: "white",
            margin: "0px 10px 10px 10px",
            borderRadius: "16px",
            overflow: "auto",
            boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
            }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation;