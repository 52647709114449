import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../components/utils/TablePaginationActions';
import useCurrencyService from '../../components/utils/CurrencyService';
import TableLoading from '../../components/loaders/TableLoading';
import TopBar from '../../components/topBar/TopBar';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import {
  DeleteVCN,
  GetVCNs 
} from '../../redux/actions/vcnActions';
import { 
  GetWalletsVCNs
} from '../../redux/actions/associationActions';
import { 
  CLEAR_BUCKET 
} from '../../redux/actionTypes';

import ConfirmationModal from '../../components/modals/ConfirmationModal';
import VirtualCardsModal from './VirtualCardsModal';
import VirtualCardsInfoModal from './VirtualCardsInfoModal';

import style from "../../global.scss";
import "./styles.scss";

const VirtualCardsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const state = useSelector(state => state.vcn);
  const associate = useSelector(state => state.associate);
  const other = useSelector(state => state.other);
  const [handleCurrency] = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [virtualCardsModalOpen, setVirtualCardsModalOpen] = useState(false);
  const [virtualCardsInfoModalOpen, setVirtualCardsInfoModalOpen] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState({});

  const [virtualCardsList, setVirtualCardsList] = useState([]);
  const [virtualCardsTotal, setVirtualCardsTotal] = useState(0);

  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [bucketName, setBucketName] = useState("");

  // --------------------- table pagination ----------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    if("userId" in params && "walletId" in params){
      dispatch(GetWalletsVCNs(params.userId, params.walletId, page+1, rowsPerPage, setLoading));
    }else{
      dispatch(GetVCNs(page+1, rowsPerPage, setLoading));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      if(!("userId" in params && "walletId" in params)){
        formatVirtualCardsList(state.vcnList.Records || []);
        setVirtualCardsTotal(state.vcnList.Total || 0);
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);  

  useEffect(() => {
    try { 
      if("userId" in params && "walletId" in params){
        formatVirtualCardsList(associate.walletsVCNsList.Records || []);
        setVirtualCardsTotal(associate.walletsVCNsList.Total || 0);
      }
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associate]); 
  
  
  useEffect(() => {
    try {
      setBucketName(other.selectedBucket || "");
    } catch (err) {}
  }, [other]);


  // ------------------------- list formatter -------------------------
  const formatVirtualCardsList = (list) => {
    setVirtualCardsList(list);
  }

  // ------------------------- delete vcn -----------------------------
  const handleDeleteVcn = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteVCN(deleteId, setDeleting)).then(() => {
      dispatch(GetVCNs(page+1, rowsPerPage, setDeleting))
    })
  }

  return (
    <Box width={"100%"}>

      <VirtualCardsModal 
        open={virtualCardsModalOpen}
        setOpen={setVirtualCardsModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <VirtualCardsInfoModal 
        open={virtualCardsInfoModalOpen}
        setOpen={setVirtualCardsInfoModalOpen}
        title={modalTitle}
        id={update}
        />

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDeleteVcn}
        />

      <TopBar
        title={("userId" in params && "walletId" in params) ? 
          <Box className="flexCenter_Row">
            <IconButton
              onClick={() => {
                navigate(-1);
                dispatch({ type: CLEAR_BUCKET });
              }}>
              <ArrowBackOutlinedIcon  fontSize='large' />
            </IconButton>
            <Typography variant='h6'>
              {bucketName}'s virtual cards
            </Typography>
          </Box>
          : "Virtual Cards" 
          }
        button1Text={("userId" in params && "walletId" in params) ? null : "Create Virtual Card"}
        button1OnClick={() => {
          setModalTitle("Create Virtual Card");
          setVirtualCardsModalOpen(!virtualCardsModalOpen);
        }}
        />

      {
        loading
        ?
        <TableLoading />
        :
          virtualCardsList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No virtual card found</Typography>
          </Box>
          :
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Card number</TableCell>
                <TableCell>Description</TableCell>
                {
                  !("userId" in params && "walletId" in params)
                  &&
                  <TableCell>From</TableCell>
                }
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                virtualCardsList.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ backgroundColor: row.CurrentStatus !== "active" && "#faf1f1" }}>
                    <TableCell>
                      <Typography fontWeight={600}>
                        {handleCurrency(row.Currency, row.Amount) || ""}
                      </Typography>
                    </TableCell>
                    <TableCell>xxxx&nbsp;xxxx&nbsp;xxxx&nbsp;{row.Last4Digits || "NA"}</TableCell>
                    <TableCell>{row.Description || "NA"}</TableCell>
                    {
                      !("userId" in params && "walletId" in params)
                      &&
                      <TableCell>
                        <Box>{"RealCard" in row.ParentConfigurations
                          ? row.ParentConfigurations.RealCard?.Alias || "NA"
                          : row.ParentConfigurations.Wallet.Alias || "NA"}&nbsp;
                        <i>({"RealCard" in row.ParentConfigurations 
                          ? row.ParentConfigurations.Network 
                          : "Wallet"})</i></Box>
                      </TableCell>
                    }
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Chip
                        label={row.CurrentStatus || "NA"} 
                        />
                    </TableCell>
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Box className="flexCenter_Row" gap={"20px"}>
                        <IconButton
                          onClick={() => {
                            setUpdate(row.Id);
                            setModalTitle("Details for xx" + row.Last4Digits || "NA");
                            setVirtualCardsInfoModalOpen(!virtualCardsInfoModalOpen);
                          }}>
                          <InfoOutlinedIcon sx={{ color: style['secondary_light']}}/>
                        </IconButton>
                        {
                          row.CurrentStatus === "active"
                          ?
                            deleteId === row.Id && deleting
                            ?
                            <CircularProgress size={"24px"}/>
                            :
                            <Tooltip title={"Delete virtual card -  " + row.Last4Digits}>
                              <IconButton
                                onClick={() => {
                                  setDeleteId(row.Id);
                                  setModalTitle("Deleting Virtual Card - " + row.Last4Digits);
                                  setDeleteConfirmationModalOpen(!deleteConfirmationModalOpen);
                                }}>
                                <DeleteTwoToneIcon sx={{ color: style['secondary_light']}}/>
                              </IconButton>
                            </Tooltip>
                          :
                          <></>  
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]} 
                  colSpan={10}
                  count={virtualCardsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default VirtualCardsPage